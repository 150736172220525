import React from 'react';
import axios from 'axios';
import { Tabs, Row, Col, Select } from 'antd';
import echarts from 'echarts/lib/echarts';
import { toJS } from 'mobx';
import { observer, inject } from 'mobx-react';
import moment from 'moment';

import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/line';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/graphic';
import 'echarts/lib/component/markLine';
import 'echarts/lib/component/markPoint';
import 'echarts/lib/component/legend';
import 'echarts/lib/chart/bar';
import 'echarts/lib/component/title';

import { getKeyFromDate, padding, getDayForMonth } from '../../utils/tools';
import { DailyIncome } from './components/DailyIncome/DailyIncome';

import './Board.scss';

const { Option } = Select;

interface IProps {
  userStore: any
}
interface IState {}

@inject('userStore')
@observer
class Board extends React.PureComponent<IProps, IState> {
  state = {
    // gridMode: 'day', // grid模式，按年 月 日
    lotteryYear: (new Date()).getFullYear(),
    gridYear: (new Date()).getFullYear(),
    gridMonth: Number(padding((new Date()).getMonth() + 1)),
    gridMode: 'date', // 模式 date ：按日期 stock 按股

    bonusYear: (new Date()).getFullYear(),
    bonusMode: 'stock', // 模式 date ：按日期 stock 按股

    yearOptions: [],
    stockAccounts: [],

    dailyEarnType: 'num', //  num || rate
    newBonusAll: 0,
  };
  resizeFn = () => {};
  stockMap = {};

  gridChart = null;
  bonusChart = null;
  dailyChart = null;
  lotteryChart = null;
  newStockChart = null;
  dailyEarnChart = null;

  getGrid() {
    let gridMode = 'year';
    this.gridChart = echarts.init(document.getElementById('grid'));

    if (this.state.gridYear && this.state.gridMonth) {
      gridMode = 'day';
    } else if (this.state.gridYear && !this.state.gridMonth) {
      gridMode = 'month';
    }

    axios.get(`/board/grid?year=${ this.state.gridYear }&month=${ this.state.gridMonth }`).then((res) => {
      let result = res.data;
      let date = [];
      let data = [];

      if (this.state.gridMode === 'date') {
        let gridData = [];
        let gridDataMap = {};

        result.forEach((item) => {
          const key = getKeyFromDate(item.date.substring(0, 10), gridMode);

          if (gridDataMap[key] === undefined) {
            //新建
            gridDataMap[key] = gridData.length;
            gridData.push({
              key,
              profit: 0,
              times: 0,
            });
          }

          // 录入
          let index = gridDataMap[key];

          gridData[index].profit += Number(item.profit);
          gridData[index].times++;
        });

        gridData.forEach((item) => {
          date.push(item.key);
          data.push(item.profit.toFixed(2));
        });

      } else if (this.state.gridMode === 'stock') {
        // 按股处理
        let fin = [];
        let indexMap = {};

        result.forEach((item) => {
          if (item.stockCode === 'sh000001') { // 这是占位的数据，无需处理，仅用处表示当天是交易日
            return;
          }

          if (indexMap[item.stockCode] === undefined) {
            indexMap[item.stockCode] = fin.length;
            fin.push({
              stockName: '',
              stockCode: '',
              value: '0',
            })
          }

          let index = indexMap[item.stockCode];

          fin[index].stockName = item.stockName;
          fin[index].stockCode = item.stockCode;
          fin[index].value = (Number(fin[index].value) + Number(item.profit)).toFixed(2);
        })

        const sortArr = fin.sort((v1, v2) => {
          return v2.value - v1.value;
        })
        // 10个以上显示其他
        let more = 0;

        ;sortArr.forEach((item, key) => {
          if (key < 9) {
            date.push(item.stockName);
            data.push(item.value);
          } else {
            more += +item.value;
          }
        });

        if (sortArr.length >= 9) {
          date.push('其他');
          data.push(more.toFixed(2));
        }
      }

      this.setGridChartData(data, date);
    })
  }

  getBonus() {
    this.bonusChart = echarts.init(document.getElementById('bonus'));

    axios.get(`/bonus?year=${ this.state.bonusYear }`).then((res: any) => {
      if (res.code === 200) {
        const date = [];
        const data = [];

        if (this.state.bonusMode === 'date') {
          if (this.state.bonusYear) {
            // 有时间
            date.push(this.state.bonusYear);

            let sum = 0;
            (res.data || []).forEach((item) => {
              sum += +item.bonus;
            });

            data.push(sum);
          } else {
            // 先计算有几个年
            const yearSum: any = {};

            ;(res.data || []).forEach((item) => {
              const year = moment(item.date).format('YYYY');

              if (!yearSum[year]) {
                yearSum[year] = +item.bonus;
              } else {
                yearSum[year] += +item.bonus;
              }
            });

            date.push(...Object.keys(yearSum));
            data.push(...Object.values(yearSum));
          }
        } else {
          const sum = {};

          ;(res.data || []).forEach((item) => {
            const name = item.stockName;

            if (!sum[name]) {
              sum[name] = {
                name,
                sum: +item.bonus,
              };
            } else {
              sum[name].sum += +item.bonus;
            }
          });

          Object.values(sum).sort((r1: any, r2: any) => {
            return r2.sum - r1.sum;
          }).forEach((item: any) => {
            date.push(item.name);
            data.push(item.sum);
          });

        }

        this.setBonusCharts(data, date);
      }
    });
  }

  setGridChartData(data, date) {
    this.gridChart.setOption({
      tooltip: {
        trigger: 'axis'
      },
      xAxis: {
        data: date,
        // axisLabel: {
        //   inside: true,
        //   color: '#fff'
        // },
        axisTick: {
          show: false
        },
        axisLine: {
          show: false
        },
        // z: 10
      },
      yAxis: {
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          color: '#999'
        }
      },
      series: [
        {
          type: 'bar',
          name: '网格收益',
          label: {
            show: true,
            position: 'top',
          },
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#83bff6' },
              { offset: 0.5, color: '#188df0' },
              { offset: 1, color: '#188df0' }
            ])
          },
          markLine: {
            data: [
              {
                type: 'average',
                name: '平均值',
                label: {
                  position: 'outsideStartTop'
                }
              }
            ]
          },
          data: data
        }
      ]
    });
  }

  setBonusCharts(data, date) {
    this.bonusChart.setOption({
      tooltip: {
        trigger: 'axis'
      },
      xAxis: {
        data: date,
        // axisLabel: {
        //   inside: true,
        //   color: '#fff'
        // },
        axisTick: {
          show: false
        },
        axisLine: {
          show: false
        },
        // z: 10
      },
      yAxis: {
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          color: '#999'
        }
      },
      series: [
        {
          type: 'bar',
          name: '分红收益',
          width: '100px',
          label: {
            show: true,
            position: 'top',
          },
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#83bff6' },
              { offset: 0.5, color: '#188df0' },
              { offset: 1, color: '#188df0' }
            ])
          },
          markLine: {
            data: [
              {
                type: 'average',
                name: '平均值',
                label: {
                  position: 'outsideStartTop'
                }
              }
            ]
          },
          data: data
        }
      ]
    });

  }

  getLottery() {
    const year = this.state.lotteryYear;
    let date = [];
    let payData = [];
    let bonusData = [];

    if (year) {
      // 单个年统计
      (new Array(12)).fill('lottery').forEach((item, ind) => {
        date.push(`${ year }-${ padding(ind + 1) }`);
        payData.push(0);
        bonusData.push(0);
      });
    } else {
      // 全部
      new Array((new Date()).getFullYear() - 2021 + 1).fill('lottery').forEach((item, ind) => {
        date.push(`${ 2021 + ind }年`);
        payData.push(0);
        bonusData.push(0);
      });
    }
    axios.get(`/board/lottery?year=${ this.state.lotteryYear }`).then((res) => {
      if (year) {
        res.data.forEach((item) => {
          const key = getKeyFromDate(item.date.substring(0, 10), 'month');
          const index = Number(key.split('-')[1]);

          payData[index] += item.pay;
          bonusData[index] += item.bonus;
        });
      } else {
        res.data.forEach((item) => {
          const key = Number(getKeyFromDate(item.date.substring(0, 10), 'year'));

          payData[key - 2021] += item.pay;
          bonusData[key - 2021] += item.bonus;
        });
      }

      this.initLottery(date, payData, bonusData);
    });
  }

  initLottery(date, payData, earnData) {
    this.lotteryChart = echarts.init(document.getElementById('lottery'));

    this.lotteryChart.setOption({
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: ['支出', '收益'],
      },
      toolbox: {
        show: true,
        feature: {
          dataView: { show: true, readOnly: false },
          magicType: { show: true, type: ['line', 'bar'] },
          restore: { show: true },
          saveAsImage: { show: true }
        }
      },
      calculable: true,
      xAxis: [
        {
          type: 'category',
          data: date
        }
      ],
      yAxis: [
        {
          type: 'value'
        }
      ],
      series: [
        {
          name: '支出',
          type: 'bar',
          data: payData,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#237A57' },
              { offset: 1, color: '#093028' },
            ])
          },
          label: {
            show: true,
            position: 'top',
          },
        },
        {
          name: '收益',
          type: 'bar',
          data: earnData,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#ff6a00' },
              { offset: 1, color: '#ee0979' }
            ])
          },
          label: {
            show: true,
            position: 'top',
          },
        }
      ]
    });
  }

  getNewStock = () => {
    axios.get(`/board/new/stock`).then((res) => {
      let date = [];
      let data = [];
      let dataMap = {};
      let allCount = 0;

      res.data.forEach((item) => {
        date.push(item.newName);
        data.push(item.newBonus);
        allCount += Number(item.newBonus);
      });

      this.initNewStock(data, date);
      this.setState({
        newBonusAll: allCount || 0,
      })
    });
  }

  initNewStock = (data, date) => {
    this.newStockChart = echarts.init(document.getElementById('newStock'));

    this.newStockChart.setOption({
      tooltip: {
        trigger: 'axis'
      },
      xAxis: {
        data: date,
        // axisLabel: {
        //   inside: true,
        //   color: '#fff'
        // },
        axisTick: {
          show: false
        },
        axisLine: {
          show: false
        },
        // z: 10
      },
      yAxis: {
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          color: '#999'
        }
      },
      series: [
        {
          type: 'bar',
          name: '新股收益',
          label: {
            show: true,
            position: 'top',
          },
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#83bff6' },
              { offset: 0.5, color: '#188df0' },
              { offset: 1, color: '#188df0' }
            ])
          },
          data: data
        }
      ]
    });
  }

  getNewBond = () => {
    axios.get(`/board/new/bond`).then((res) => {
      let date = [];
      let data = [];
      let dataMap = {};

      res.data.forEach((item) => {
        const key = getKeyFromDate(item.date.substring(0, 10), 'year');

        if (dataMap[key] === undefined) {
          dataMap[key] = {};
        }

        if (dataMap[key][item.belongTo] === undefined) {
          dataMap[key][item.belongTo] = 0;
        }

        dataMap[key][item.belongTo] += Number(item.newBonus);
      });

      // console.log('dataMap', dataMap);
      // console.log('toJS(nextProps.accountMap).stockAccounts', this.state.stockAccounts);
      date = Object.keys(dataMap)
      data = Object.values(dataMap)

      // console.log(data, date)
      // this.initNewBond(data, date);
    });
  }

  initNewBond = (data, date) => {

  }

  changeInput = (name, val) => {
    this.setState({
      [name]: val
    });
  }

  changeGridFilter = (name, val) => {
    this.changeInput(name, val);

    setTimeout(() => {
      this.getGrid();
    });
  }

  changeBonusFilter = (name, val) => {
    this.changeInput(name, val);

    setTimeout(() => {
      this.getBonus();
    });
  }

  changeLotterFilter = (name, val) => {
    this.changeInput(name, val);

    setTimeout(() => {
      this.getLottery();
    });
  }

  init() {
    // 初始化时间
    let thisYear = (new Date()).getFullYear();
    let years = [];

    new Array(thisYear - 2020).fill('1').forEach((item, ind) => {
      years.push(2020 + ind + 1);
    });

    this.setState({
      yearOptions: years,
    });
  }

  componentWillReceiveProps(nextProps) {
    // this.setState({
    //   stockAccounts: toJS(nextProps.accountMap).stockAccounts
    // })
  }

  componentDidMount() {
    this.init();

    // this.getDaily();
    this.getGrid(); // 按日、月年都是柱状图 
    this.getBonus();
    this.getLottery();
    this.getNewStock();
    this.getNewBond();

    window.addEventListener('resize', this.resizeFn = () => {
      this.gridChart && this.gridChart.resize();
      // this.dailyChart.resize();
      // this.dailyEarnChart.resize();
      this.bonusChart &&  this.bonusChart.resize();
      this.lotteryChart && this.lotteryChart.resize();
      this.newStockChart && this.newStockChart.resize();
    });
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', this.resizeFn);
  }

  render() {
    return (
      <div style={{ width: '100%' }}>
        <DailyIncome data={{
          newBonusAll: this.state.newBonusAll,
        }}></DailyIncome>
        <br />
        <h3>网格收益图</h3>
        <Row gutter={ 16 }>
          <Col md={ 4 } xs={ 12 }>
            <Select value={ this.state.gridYear } style={{ width: '100%' }} onChange={ (value) => { this.changeGridFilter('gridYear', value) } }>
              <Option value="">全部</Option>
              {
                this.state.yearOptions.map((item) => {
                  return (
                    <Option value={ item } key={ item }>{ item }年</Option>
                  )
                })
              }
            </Select>
          </Col>
          {
            Number(this.state.gridYear) !== 0 && <Col md={ 4 } xs={ 12 }>
              <Select value={ this.state.gridMonth } style={{ width: '100%' }} onChange={ (value) => { this.changeGridFilter('gridMonth', value) } }>
                <Option value="">全部</Option>
                {
                  Array.from(new Array(12)).map((item, ind) => {
                    return (
                      <Option value={ ind + 1 } key={ ind }>{ ind + 1 }月</Option>
                    )
                  })
                }
              </Select>
            </Col>
          }
          <Col md={ 4 } xs={ 12 }>
            <Select value={ this.state.gridMode } style={{ width: '100%' }} onChange={ (value) => { this.changeGridFilter('gridMode', value) } }>
              <Option value="date">按日期</Option>
              <Option value="stock">按股票</Option>
            </Select>
          </Col>
        </Row>
        <br />
        <div id="grid" style={{ width: '100%', height: 400 }}></div>
        <h3>分红收益图</h3>
        <Row gutter={ 16 }>
          <Col md={ 4 } xs={ 12 }>
            <Select value={ this.state.bonusYear } style={{ width: '100%' }} onChange={ (value) => { this.changeBonusFilter('bonusYear', value) } }>
              <Option value="">全部</Option>
              {
                this.state.yearOptions.map((item) => {
                  return (
                    <Option value={ item } key={ item }>{ item }年</Option>
                  )
                })
              }
            </Select>
          </Col>
          <Col md={ 4 } xs={ 12 }>
            <Select value={ this.state.bonusMode } style={{ width: '100%' }} onChange={ (value) => { this.changeBonusFilter('bonusMode', value) } }>
              <Option value="date">按日期</Option>
              <Option value="stock">按股票</Option>
            </Select>
          </Col>
        </Row>
        <div id="bonus" style={{ width: '100%', height: 400 }}></div>
        <h3>彩票收益/支出</h3>
        <Row gutter={ 16 }>
          <Col span={ 4 }>
            <Select value={ this.state.lotteryYear } style={{ width: '100%' }} onChange={ (value) => { this.changeLotterFilter('lotteryYear', value) } }>
              <Option value="">全部</Option>
              {
                this.state.yearOptions.map((item) => {
                  return (
                    <Option value={ item } key={ item }>{ item }年</Option>
                  )
                })
              }
            </Select>
          </Col>
        </Row>
        <div id="lottery" style={{ width: '100%', height: 400 }}></div>
        <h3>新股收益</h3>
        <div id="newStock" style={{ width: '100%', height: 400 }}></div>
        <h3>新债收益</h3>
        <div id="newBond" style={{ width: '100%', height: 400 }}></div>
      </div>
    );
  }
}

export default Board;