import React from 'react';
import { Table, Select, Row, Col, Button, message, InputNumber, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';
import filesize from 'filesize';
import copy from 'copy-to-clipboard';
import Carousel, { Modal, ModalGateway } from 'react-images';

import './Uploader.scss';

class Uploader extends React.PureComponent {
  state = {
    dataSource: [],
    columns: [{
      title: '时间',
      dataIndex: 'date',
      key: 'date',
      render: (value) => {
        return moment(value).format('YYYY-MM-DD HH:mm:ss');
      }
    }, {
      title: '文件名',
      dataIndex: 'filename',
      key: 'filename',
      render: (val, recorder) => {
        return (
          <span className='table-primary' onClick={ () => { this.view(recorder) } }>{ val }</span>
        )
      }
    }, {
      title: '文件大小',
      dataIndex: 'filesize',
      key: 'filesize',
      width: 110,
      render: (val) => {
        return filesize(val);
      }
    }, {
      title: 'md5',
      dataIndex: 'md5',
      key: 'md5'
    }, {
      title: '展示',
      dataIndex: 'fileType',
      key: 'fileType',
      render: (fileType, recorder) => {
        if (['image/jpeg', 'image/png', 'image/jpg'].includes(fileType)) {
          return (
            <img width={ 100 } className='pointer' src={ recorder.filePath } onClick={ () => { this.view(recorder) } } />
          )
        } else {
          return '不支持该格式的文件预览';
        }
      }
    }, {
      title: '操作',
      width: '160px',
      render: (val, recorder) => {
        return (
          <React.Fragment>
            <span className='table-primary' onClick={ () => { this.view(recorder) } }>预览</span>
            <span className='table-primary' onClick={ () => { this.copyLink(recorder) } }>复制链接</span>
          </React.Fragment>
        )
      }
    }],
    page: 1,
    pageSize: 20,
    total: 0,
    imageView: false,
    images: [],
  }

  token = localStorage.getItem('token');

  view = (recorder) => {
    const fileType = recorder.fileType;

    if (['image/jpeg', 'image/png', 'image/jpg'].includes(fileType)) {
      this.setState({
        imageView: true,
        images: [{ source: recorder.filePath }]
      })
    } else {
      message.error('不支持该格式的文件预览');
    }
  }

  copyLink = (recorder) => {
    if (copy(recorder.filePath)) {
      message.success('拷贝成功');
    } else {
      message.error('拷贝失败');
    }
  }

  paginationChange = (page, pageSize) => {
    this.setState({
      page,
      pageSize,
    }, () => {
      this.getUpload();
    })
  }

  addNew = () => {}
  toggleModal = () => {
    this.setState({
      imageView: false,
      images: []
    })
  }

  getUpload = () => {
    axios.get(`/uploader?page=${this.state.page}&pageSize=${this.state.pageSize}`).then((res) => {
      this.setState({
        dataSource: res.data,
      });
    })
  }

  componentDidMount() {
    this.getUpload();
  }

  render() {
    const that = this;
    const props = {
      name: 'file',
      action: axios.defaults.baseURL + 'uploader',
      headers: {
        Authorization: 'Bearer ' + that.token,
      },
      onChange(info) {
        if (info.file.status !== 'uploading') {
          console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
          message.success(`${info.file.name} 上传成功.`);
          that.getUpload();
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} 上传失败.`);
        }
      },
      showUploadList: false,
    };
    const styleInit = {
      header: (base, state) => ({ //头部样式
        position: 'absolute',
        top: 90,
        right: 90,
        zIndex: 9999,
      }),
      view: (base, state) => ({
        textAlign: 'center',
        height: state.isFullscreen?'100%':600  //当点击全屏的时候图片样式
      })
    }

    return (
      <div>
        <Row gutter={ 16 }>
          <Col span={ 3 } offset={ 21 }>
            <Upload {...props}>
              <Button icon={<UploadOutlined />}>上传新文件</Button>
            </Upload>
          </Col>
        </Row>
        <br />
        <Table
          pagination={{ current: this.state.page, pageSize: this.state.pageSize, total: this.state.total, onChange: this.paginationChange }}
          bordered={ true }
          dataSource={ this.state.dataSource }
          columns={ this.state.columns }
          rowKey={ item => item._id }
        />
        <ModalGateway>
          { this.state.imageView ? (
            <Modal onClose={ this.toggleModal }>
              <Carousel views={ this.state.images } />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    )
  }
}

export default Uploader;
