import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import axios from 'axios';
import { createHashHistory } from 'history';
import { message } from 'antd';
import * as serviceWorker from './serviceWorker';

import 'antd/dist/antd.css';
import './index.scss';
import '@/assets/font/iconfont.css';

const history = createHashHistory();

axios.defaults.baseURL = 'https://stock.zhuyuntao.cn/api/'; // process.env.NODE_ENV === 'production' ? 'https://stock.zhuyuntao.cn/api/' : /* 'https://stock.zhuyuntao.cn/api/'// */'http://127.0.0.1:3001/api/';

axios.interceptors.request.use(config => {
  const token = localStorage.getItem('token');

  config.headers.common['Authorization'] = 'Bearer ' + token;

  return config;
});

axios.interceptors.response.use((response) => {
  const data = response.data;

  if (data.code === 200) {
    return data;
  }
}, (error) => {
  if (error.response) {
    if (error.response.status === 401 || error.response.config.url === '/login') {
      history.push('/')
    }
  
    message.error(`${ error.response.data.msg || '接口异常' }`);
  }

  return Promise.reject(error);
});

ReactDOM.render(
  // <React.StrictMode>
    <App />
  /* </React.StrictMode> */,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
