import React from 'react';
import { HashRouter, Route, /* Redirect, */ Switch } from 'react-router-dom';

import Overall from '@/components/Overall/Overall'
import Login from '@/views/Login/Login'
import Home from '@/views/Home/Home'
import Board from '@/views/Board/Board'
import Stock from '@/views/New/Stock/Stock'
import Bond from '@/views/New/Bond/Bond'
import Bonus from '@/views/Bonus/Bonus'
import Aged from '@/views/Aged/Aged'
import Daily from '@/views/Daily/Daily'
import Lottery from '@/views/Lottery/Lottery'
import UserInfo from '@/views/UserInfo/UserInfo'
import Grid from '@/views/Grid/Grid'
import Shanghai from '@/views/Setting/Shanghai/Shanghai'
import Shenzhen from '@/views/Setting/Shenzhen/Shenzhen'
import BackUp from '@/views/Setting/BackUp/BackUp'
import Log from '@/views/Setting/Log/Log'
import ExRate from '@/views/Setting/ExRate/ExRate'
import Debt from '@/views/Debt/Debt'
import Uploader from '@/views/Uploader/Uploader'
import View from '@/views/Blog/View/View'
import Visit from '@/views/Blog/Visit/Visit'
import Comment from '@/views/Blog/Comment/Comment'

interface IProps {
  // propsrouteList: any[]
}

interface IState {}

class Router extends React.Component<IProps, IState> {
  // 渲染Route组件
  renderRoute = () => {
    return (
      <Switch>
        {/* <Route path='/' exact render={() => <Redirect to='/labelCenter' />}></Route> */}
        <Route path="/" exact component={ Login }></Route>
        <Overall>
          <Switch>
            <Route path="/home" component={ Home }></Route>
            <Route path="/board" component={ Board }></Route>
            <Route path="/new/bond" component={ Bond }></Route>
            <Route path="/new/stock" component={ Stock }></Route>
            <Route path="/daily" component={ Daily }></Route>
            <Route path="/lottery" component={ Lottery }></Route>
            <Route path="/userInfo" component={ UserInfo }></Route>
            <Route path="/grid" component={ Grid }></Route>
            <Route path="/debt" component={ Debt }></Route>
            <Route path="/uploader" component={ Uploader }></Route>
            <Route path="/bonus" component={ Bonus }></Route>
            <Route path="/aged" component={ Aged }></Route>
            <Route path="/setting/shanghai" component={ Shanghai }></Route>
            <Route path="/setting/shenzhen" component={ Shenzhen }></Route>
            <Route path="/setting/backup" component={ BackUp }></Route>
            <Route path="/setting/log" component={ Log }></Route>
            <Route path="/setting/exRate" component={ ExRate }></Route>
            <Route path="/blog/visit" component={ Visit }></Route>
            <Route path="/blog/view" component={ View }></Route>
            <Route path="/blog/comment" component={ Comment }></Route>
          </Switch>
        </Overall>
      </Switch>
    )
  }

  render() {
    return (
      <HashRouter>
        {
          this.renderRoute()
        }
      </HashRouter>
    );
  }
}
// 将redux中的路由信息导入致props
export default Router;
