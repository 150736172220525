import React from 'react';
import { Divider, Button, Progress, Table, message } from 'antd'
import axios from 'axios';
import moment from 'moment';

class BackUp extends React.PureComponent {
  state = {
    doing: false,
    percent: 0,
    columns: [{
      title: '获取时间',
      dataIndex: 'date',
      key: 'date',
      render: (value) => {
        return moment(value).format('YYYY-MM-DD HH:mm:ss');
      }
    }, {
      title: '美元兑RMB',
      dataIndex: 'USD',
      key: 'USD'
    }, {
      title: '港币兑RMB',
      dataIndex: 'HKD',
      key: 'HKD',
    }],
    dataSource: [],
  }
  timer = null;

  getRate = () => {
    axios.get('/exRate').then((res: any) => {
      if (res.code === 200) {
        this.setState({
          dataSource: res.data,
        })
      }
    });
  }

  startGetting = () => {
    axios.get('/getExRateNow').then((res: any) => {
      if (res.code === 200) {
        message.success('获取成功');

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    });
  }

  componentDidMount() {
    this.getRate();
  }

  render() {
    return (
      <div className='backup'>
        <div className='card-title'>汇率</div>
        <Divider />
        <Button onClick={ this.startGetting }>开始获取</Button>
        <br />
        <Table
          pagination={false}
          bordered={ true }
          dataSource={ this.state.dataSource }
          columns={ this.state.columns }
          rowKey={ item => item._id }
        />
      </div>
    )
  }
}

export default BackUp;
