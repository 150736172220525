import React from 'react';
import { Divider, Button, Progress, Table } from 'antd'
import axios from 'axios';
import moment from 'moment';
import filesize from 'filesize';

import { download } from '../../../utils/tools';

class BackUp extends React.PureComponent {
  state = {
    doing: false,
    percent: 0,
    columns: [{
      title: '备份时间',
      dataIndex: 'date',
      key: 'date',
      render: (value) => {
        return moment(value).format('YYYY-MM-DD HH:mm:ss');
      }
    }, {
      title: '压缩包名',
      dataIndex: 'filename',
      key: 'filename'
    }, {
      title: '压缩包大小',
      dataIndex: 'filesize',
      key: 'filesize',
      render: (val) => {
        return filesize(val);
      }
    }, {
      title: 'md5值',
      dataIndex: 'md5',
      key: 'md5'
    }, {
      title: '类型',
      dataIndex: 'manual',
      key: 'manual',
      render: (value) => {
        return value ? '手动' : '自动';
      }
    }, {
      title: '操作',
      width: '100px',
      dataIndex: 'filename',
      key: 'filename',
      render: (val, record) => {
        let url = `https://image.zhuyuntao.cn/stock/${ moment(record.date).format('YYYY') }/${ val }`;

        return (
          <React.Fragment>
            <span className='table-primary' onClick={ () => { download(url) } }>下载</span>
          </React.Fragment>
        )
      }
    }],
    dataSource: [],
  }
  timer = null;

  startBackup = () => {
    axios.post('/startBackup').then((res: any) => {
      if (res.code !== 200) {
        this.setState({
          doing: true,
        })
        this.startGetStatus();
      }
    });
  }

  startGetStatus = () => {
    this.timer = setInterval(() => {
      axios.get('/backup/status').then((res: any) => {
        let status = res.data
        if (!res.code) {

          if (!status) {
            // 结束了
            this.setState({
              doing: false,
              percent: 0,
            });
            this.clearGetStatus();
          }
        } else {
          this.setState({
            percent : status / 5 * 100
          });
        }
      })
    }, 1000 * 1)
  }

  clearGetStatus = () => {
    clearInterval(this.timer);
    this.timer = null;

    this.getBackup();
  }

  getBackup = () => {
    axios.get('/backup').then((res) => {
      this.setState({
        dataSource: res.data
      });
    });
  }

  componentDidMount() {
    this.getBackup();
  }

  render() {
    return (
      <div className='backup'>
        <div className='card-title'>数据备份</div>
        <Divider />
        <Button onClick={ this.startBackup }>开始备份</Button>
        <div>
          {
            this.state.doing && <Progress
              strokeColor={{
                from: '#108ee9',
                to: '#87d068',
              }}
              percent={ this.state.percent }
              status="active"
            />
          }
        </div>
        <br />
        <Table
          pagination={false}
          bordered={ true }
          dataSource={ this.state.dataSource }
          columns={ this.state.columns }
          rowKey={ item => item._id }
        />
      </div>
    )
  }
}

export default BackUp;
