import React from 'react';
import { Table, Select, Row, Col, Button, Modal, Input } from 'antd';
import axios from 'axios';
import moment from 'moment';

import './View.scss';

class View extends React.PureComponent {
  state = {
    columns: [{
      title: '链接地址',
      dataIndex: 'link',
      key: 'link',
      ellipsis: true,
      render: (value) => {
        return <a href={ value } target='_blank'>{ value ? decodeURIComponent(value) : '' }</a>;
      }
    }, {
      title: '查看次数',
      dataIndex: 'time',
      width: 100,
      key: 'time'
    }],
    dataSource: [],

    page: 1,
    pageSize: 20,
    total: 0,
    searchString: '',
  }

  paginationChange = (page, pageSize) => {
    this.setState({
      page,
      pageSize,
    }, () => {
      this.getView();
    })
  }

  search = () => {
    this.setState({
      page: 1,
    }, () => {
      this.getView();
    })
  }

  getView = () => {
    axios.get(`/blog/view?search=${ this.state.searchString }&page=${ this.state.page }&pageSize=${ this.state.pageSize }`).then((res: any) => {
      this.setState({
        dataSource: res.data,
        page: res.page.page,
        pageSize: res.page.pageSize,
        total: res.page.total,
      })
    })
  }

  componentDidMount() {
    this.getView();
  }

  render() {
    return <div className=''>
      <Row gutter={ 16 }>
        <Col span={ 6 }>
          <Input value={ this.state.searchString } placeholder='请输入内容' onChange={ (e) => { this.setState({ 'searchString': e.target.value }) } } />
        </Col>
        <Col span={ 2 } offset={ 16 }>
          <Button onClick={ this.search }>搜索</Button>
        </Col>
      </Row>
      <br />
      <Table
        bordered={ true }
        dataSource={ this.state.dataSource }
        pagination={{ current: this.state.page, pageSize: this.state.pageSize, total: this.state.total, onChange: this.paginationChange }}
        columns={ this.state.columns }
        rowKey={ item => item._id }
      />
    </div>
  }
}

export default View;
