import React from 'react';
import { Table, Select, Row, Col, Button, Modal, Input, DatePicker, message, InputNumber, Descriptions } from 'antd';
import axios from 'axios';
import moment, { Moment } from 'moment';
import {
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { observer, inject } from 'mobx-react';

import { percentage, round2 } from '../../utils/tools';

import './Lottery.scss'

const { Option } = Select;
const { confirm } = Modal;
const { RangePicker } = DatePicker;

let stockObj = {}

interface IProps {
  userStore: any
}
interface IState {}

@inject('userStore')
@observer
class Grid extends React.PureComponent<IProps, IState> {
  state = {
    dataSource: [],
    columns: [{
      title: '时间',
      dataIndex: 'date',
      key: 'date',
      render: (value) => {
        return value.substring(0, 10)
      }
    }, {
      title: '盈利',
      dataIndex: 'bonus',
      key: 'bonus'
    }, {
      title: '花费',
      dataIndex: 'pay',
      key: 'pay'
    }, {
      title: '操作',
      width: '150px',
      render: (val, recorder) => {
        return (
          <React.Fragment>
            <span className='table-warning' onClick={ () => { this.modifyLottery(recorder) } }>编辑</span>
            <span className='table-delete' onClick={ () => { this.deleteLottery(recorder._id) } }>删除</span>
          </React.Fragment>
        )
      }
    }],
    startTime: moment(`${ (new Date()).getFullYear() }-01-01`, 'YYYY-MM-DD'),
    endTime: moment(`${ (new Date()).getFullYear() }-${ (new Date()).getMonth() + 1 }-${ (new Date()).getDate() }`, 'YYYY-MM-DD'),
    page: 1,
    pageSize: 20,
    total: 0,
    pay: '',
    bonus: '',
    date: moment(`${ (new Date()).getFullYear() }-${ (new Date()).getMonth() + 1 }-${ (new Date()).getDate() }`, 'YYYY-MM-DD'),
    modifyId: '',
    lotteryModal: 0, // 0 表示关闭，1 表示增加状态 2 表示编辑状态
    list: [],
    range: [moment(`${ (new Date()).getFullYear() }-01-01`, 'YYYY-MM-DD'), moment(`${ (new Date()).getFullYear() }-${ (new Date()).getMonth() + 1 }-${ (new Date()).getDate() }`, 'YYYY-MM-DD')],
  }

  deleteLottery = (id) => {
    confirm({
      title: '确认删除该数据么?',
      icon: <ExclamationCircleOutlined />,
      content: '删除不可找回？',
      okText: '确定',
      okType: 'danger',
      cancelText: '取消',
      onOk: () => {
        axios.delete(`/lottery?id=${ id }`).then((res) => {
          message.success('删除成功');

          this.getLottery();
        }, () => {
          message.error('删除失败');
        });
      },
    });
  }

  paginationChange = (page, pageSize) => {
    this.setState({
      page,
      pageSize,
    }, () => {
      this.getLottery();
    })
  }

  changeInput = (name, val) => {
    if (name === 'range') {
      if (val === null) {
        val = [undefined, undefined];
      }
      this.setState({
        startTime: val[0],
        endTime: val[1],
      });
    } else {
      this.setState({
        [name]: val
      });
    }
  }

  modifyLottery = (rec) => {
    this.setState({
      lotteryModal: 2,
      modifyId: rec._id,
      pay: rec.pay,
      bonus: rec.bonus,
      date: moment(rec.date.substring(0, 10), 'YYYY-MM-DD'),
    })
  }

  addNewLottery = () => {
    this.setState({
      lotteryModal: 1,
      pay: '',
      bonus: '',
      date: moment(`${ (new Date()).getFullYear() }-${ (new Date()).getMonth() + 1 }-${ (new Date()).getDate() }`, 'YYYY-MM-DD'),
    });
  }

  handleOk = () => {
    if (!this.state.date || !this.state.pay || !this.state.bonus) {
      message.error('请输入值');
      
      return;
    }

    // 检查数据
    const info: any = {
      date: new Date(this.state.date.format().substring(0, 10)),
      bonus: +this.state.bonus - 0,
      pay: +this.state.pay - 0,
    }

    this.handleCancel()

    let action

    if (this.state.lotteryModal === 1) {
      action = axios.post
    } else {
      action = axios.put
      info.id = this.state.modifyId
    }

    action('/lottery', info).then((res) => {
      message.success('操作成功');
      this.getLottery();

      // 恢复默认
      this.setState({
        date: moment(`${ (new Date()).getFullYear() }-${ (new Date()).getMonth() + 1 }-${ (new Date()).getDate() }`, 'YYYY-MM-DD'),
        bonus: '',
        pay: '',
      })
    }, () => {
      message.error('操作失败');
    })
  }

  handleCancel = () => {
    this.setState({ lotteryModal: 0 })
  }

  getLottery = () => {
    axios.get('/lottery', {
      params: {
        // startTime: this.state.startTime,
        // endTime: this.state.endTime,
        page: this.state.page,
        pageSize: this.state.pageSize,
      }
    }).then(((res: any) => {
      this.setState({
        list: [...res.data],
        page: res.page.page,
        pageSize: res.page.pageSize,
        total: res.page.total,
      });
    }))
  }

  searchLottery = () => {

  }

  componentDidMount() {
    this.getLottery();
  }

  render() {
    return (
      <div className='daily'>
        <Row>
          {/* <Col span={ 6 }>
            <RangePicker
              value={ [this.state.startTime, this.state.endTime] }
              onChange={ (value) => { this.changeInput('range', value) }}
            />
          </Col> */}
          <Col span={ 2 } offset={ 20 }>
            <Button type='primary' onClick={ this.getLottery }>查询</Button>
          </Col>
          <Col span={ 2 }>
            <Button type='default' onClick={ this.addNewLottery }>新增</Button>
          </Col>
        </Row>
        <br />
        <Table
          bordered={ true }
          pagination={{ current: this.state.page, pageSize: this.state.pageSize, total: this.state.total, onChange: this.paginationChange }}
          dataSource={ this.state.list }
          columns={ this.state.columns }
          rowKey={ item => item._id }
        />
        {
          !!this.state.lotteryModal && <Modal
            title={ this.state.lotteryModal === 1 ? '新增' : '编辑'}
            visible={ !!this.state.lotteryModal }
            onOk={ this.handleOk }
            onCancel={ this.handleCancel }
            width="840px"
          >
            <Row gutter={ 16 }>
              <Col span={ 3 } className='must'>时间：</Col>
              <Col span={ 9 }>
                <DatePicker
                  style={{ width: '100%' }}
                  value={ this.state.date }
                  onChange={ (value) => { this.changeInput('date', value) }}
                />
              </Col>
              <Col span={ 3 } className='must'>盈利：</Col>
              <Col span={ 9 }>
                <Input value={ this.state.bonus } onChange={ (e) => { this.changeInput('bonus', e.currentTarget.value) }} style={{ width: '100%' }} />
              </Col>
            </Row>
            <br />
            <Row gutter={ 16 }>
              <Col span={ 3 } className='must'>花费：</Col>
              <Col span={ 9 }>
                <Input value={ this.state.pay } onChange={ (e) => { this.changeInput('pay', e.currentTarget.value) }} style={{ width: '100%' }} />
              </Col>
            </Row>
            <br />
          </Modal>
        }
      </div>
    )
  }
}

export default Grid;
