import React from 'react';
import { Descriptions, Divider } from 'antd';

interface ShenzhenProps {}

const Shenzhen: React.FunctionComponent<ShenzhenProps> = () => {
  return (
    <div className='shenzhen'>
      <div className='card-title'>深市信息</div>
      <Divider />
      <Descriptions
        bordered
      >
        <Descriptions.Item label="印花税">0.001</Descriptions.Item>
        <Descriptions.Item label="过户费">0.00002</Descriptions.Item>
      </Descriptions>
    </div>
  )
}

export default Shenzhen;
