import React from 'react';
import { Table, Descriptions, Divider, Button, Row, Col } from 'antd';

import { percentage } from '@/utils/tools';

import './Account.scss';

interface IProps {
  goToDetail: () => void
  name: string
  stockAccounts: any
}
interface IState {}

class Account extends React.PureComponent<IProps, IState> {
  state = {
    columns: [{
      title: '证券名',
      dataIndex: 'business',
      key: 'business'
    }, {
      title: '证券号',
      dataIndex: 'uid',
      key: 'uid'
    }, {
      title: '属于',
      dataIndex: 'belong',
      key: 'belong'
    }, {
      title: '操作',
      width: '180px',
      render: (val, recorder) => {
        return (
          <React.Fragment>
            <span className='table-delete'>删除</span>
            <span className='table-primary' onClick={ () => { this.toDetail(recorder.accountId) } }>查看详情</span>
          </React.Fragment>
        )
      }
    }]
  }

  toDetail = (accountId) => {
    sessionStorage.setItem('accountId', accountId)
    
    this.props.goToDetail()
  }

  addNew = () => {

  }

  render() {
    
    return (
      <React.Fragment>
        <div className='card-title'>账户信息</div>
        
        <Divider />
        <Descriptions
          bordered
        >
          {/* <Descriptions.Item label="用户名称">{ this.props.name }</Descriptions.Item> */}
          <Descriptions.Item label="证券数">{ this.props.stockAccounts.length }</Descriptions.Item>
          {/* <Descriptions.Item label="统计开启时间">2020年7月30号</Descriptions.Item> */}
        </Descriptions>
        <br />
        <Row>
          <Col span={ 2 } offset={ 22 }>
            <Button type="primary" onClick={ this.addNew }>新增</Button>
          </Col>
        </Row>
        <br />
        <Table
          bordered={ true }
          pagination={ false }
          dataSource={ this.props.stockAccounts }
          columns={ this.state.columns }
          rowKey={ item => item.belong }
        />
      </React.Fragment>
    )
  }
}

export default Account;
