import React from 'react';
import { Table, Select, Row, Col, Button, Modal, Input, Tabs } from 'antd';
import axios from 'axios';

import './Log.scss';

const { Option } = Select;
const { TabPane } = Tabs;

export default class Log extends React.PureComponent {
  state = {
    columns: [{
      title: '链接地址',
      dataIndex: 'link',
      key: 'link',
      ellipsis: true,
      render: (value) => {
        return <a href={ value } target='_blank'>{ value ? decodeURIComponent(value) : '' }</a>;
      }
    }, {
      title: '查看次数',
      dataIndex: 'time',
      width: 100,
      key: 'time'
    }],
    dataSource: [],

    page: 1,
    pageSize: 20,
    total: 0,

    type: 'info',
    zoneName: '',
    zoneOption: [],
    content: [],
  }

  paginationChange = (page, pageSize) => {
    this.setState({
      page,
      pageSize,
    }, () => {
      this.getLog();
    })
  }

  changeZone = (value) => {
    this.setState({
      'zoneName': value,
    }, () => {
      this.getLog();
    })
  }

  changeTab = (activeKey) => {
    this.setState({
      type: activeKey,
      zoneName: '',
    }, () => {
      this.getLog();
    })
  }

  getLog = () => {
    axios.get(`/log?zoneName=${ this.state.zoneName }&type=${ this.state.type }`).then((res: any) => {
      this.setState({
        zoneOption: res.filenames,
        content: res.data ? decodeURIComponent(res.data).split('\n').reverse() : [],
        zoneName: res.searchFilename,
      })
    })
  }

  componentDidMount() {
    this.getLog();
  }

  render() {
    return <div className='log'>
      <Tabs activeKey={ this.state.type } onChange={ this.changeTab }>
        <TabPane tab="正常日志" key="info"></TabPane>
        <TabPane tab="异常日志" key="error"></TabPane>
      </Tabs>
      <Row gutter={ 16 }>
        <Col span={ 6 }>
          <Select value={ this.state.zoneName } style={{ width: '100%' }} onChange={ (value) => { this.changeZone(value) } }>
            {
              this.state.zoneOption.map((item) => {
                return (
                  <Option value={ item } key={ item }>{ item }</Option>
                )
              })
            }
          </Select>
        </Col>
      </Row>
      <br />
      <div className='log-container'>
        {
          this.state.content.map((res, ind) => {
            return <p className='log-row' key={ ind }>{ decodeURIComponent(res) }</p>
          })
        }
        {
          !this.state.content.length && <p className='log-row'>无数据</p>
        }
      </div>
    </div>
  }
}