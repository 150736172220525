import React from 'react';
import { Descriptions, Divider } from 'antd';

interface ShanghaiProps {}

const Shanghai: React.FunctionComponent<ShanghaiProps> = () => {
  return (
    <div className='shanghai'>
      <div className='card-title'>沪市信息</div>
      <Divider />
      <Descriptions
        bordered
      >
        <Descriptions.Item label="印花税">0.001</Descriptions.Item>
        <Descriptions.Item label="过户费">0.00002</Descriptions.Item>
      </Descriptions>
    </div>
  )
}

export default Shanghai;
