export const percentage = (val: number, digit: number): number => {
  const multiple = Math.pow(10, digit)
  
  return Math.round(val * 100 * multiple) / multiple
}

export const round2 = function(val) {
  return Math.round(val * 100) / 100
}

export const getDayForMonth = (year: number | string, month: number | string) => {
  return (new Date(Number(year), Number(month), 0)).getDate()
}

export const getKeyFromDate = (str: string, type: string) => {
  if (type === 'day') {
    return str;
  }

  let date = str.split('-');

  return type === 'year' ? date[0] : `${ date[0] }-${ date[1] }`;
}

export const padding = (num) => {
  return ('0' + num).slice(-2);
}

export const download = (url) => {
  let oA: any = document.createElement('a');
    
  oA.setAttribute('href', url);
  oA.download = true;
  
  document.body.appendChild(oA);
  oA.click();

  document.body.removeChild(oA);
}