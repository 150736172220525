let modules = {}

require.context('./', true, /\.js$/).keys().forEach(item => {
  if (['index.js', 'fetch.js', 'api.js'].indexOf(item) >= 0) {
    return false
  }

  const file = item.replace('./', '')
  const data = require(`/${ file }`)
  const { default: d, ...o } = data

  modules = { ...modules, ...d, ...o }
})

export default modules
