import React from 'react';
import { observer, inject } from 'mobx-react';
import { Tabs, Table, Row, Col, Button, Modal, DatePicker, Input, message } from 'antd';
import moment from 'moment';
import axios from 'axios';
import {
  ExclamationCircleOutlined,
} from '@ant-design/icons';

import AccountDetail from './components/AccountDetail/AccountDetail'
import Account from './components/Account/Account'

import './UserInfo.scss';

const { TabPane } = Tabs;
const { TextArea } = Input;
const { confirm } = Modal;

interface IProps {
  userStore: any
}
interface IState {}

@inject('userStore')
@observer
class UserInfo extends React.PureComponent<IProps, IState> {
  state = {
    columns: [{
      title: '时间',
      dataIndex: 'date',
      key: 'date',
      width: 150,
      render: (value) => {
        return value.substring(0, 10)
      }
    }, {
      title: '增减',
      dataIndex: 'variation',
      width: 150,
      key: 'variation'
    }, {
      title: '投资总额',
      dataIndex: 'total',
      width: 150,
      key: 'total'
    }, {
      title: '备注',
      dataIndex: 'remark',
      key: 'remark'
    }, {
      title: '操作',
      width: '130px',
      render: (val, recorder) => {
        return (
          <React.Fragment>
            <span className='table-warning' onClick={ () => { this.modifyPrincipal(recorder) } }>编辑</span>
            <span className='table-delete' onClick={ () => { this.deletePrincipal(recorder._id) } }>删除</span>
          </React.Fragment>
        )
      }
    }],
    mode: 0,
    activeKey: '1',
    page: 1,
    pageSize: 100,
    total: 0,
    dataSource: [],
    principalModal: 0,
    date: moment(`${ (new Date()).getFullYear() }-${ (new Date()).getMonth() + 1 }-${ (new Date()).getDate() }`, 'YYYY-MM-DD'),
    variation: '',
    remark: '',
    modifyId: '',
  }

  goToDetail = () => {
    this.setState({ mode: 1 })
  }

  goBack = () => {
    this.setState({ mode: 0 })
  }

  paginationChange = (page, pageSize) => {
    this.setState({
      page,
      pageSize,
    }, () => {
      // this.get();
    })
  }

  changeTab = (activeKey) => {
    this.setState({
      activeKey
    })
  }

  addNew = () => {
    this.setState({
      principalModal: 1,
      date: moment(`${ (new Date()).getFullYear() }-${ (new Date()).getMonth() + 1 }-${ (new Date()).getDate() }`, 'YYYY-MM-DD'),
      variation: '',
      remark: '',
    })
  }

  modifyPrincipal = (item) => {
    this.setState({
      principalModal: 2,
      modifyId: item._id,

      date: moment(item.date.substring(0, 10), 'YYYY-MM-DD'),
      variation: item.variation,
      remark: item.remark,
    })
  }

  deletePrincipal = (_id) => {
    confirm({
      title: 'Are you sure delete this task?',
      icon: <ExclamationCircleOutlined />,
      content: '确定删除？',
      okText: '确定',
      okType: 'danger',
      cancelText: '取消',
      onOk: () => {
        axios.delete(`/principal?_id=${ _id }`).then((res) => {
          message.success('删除成功');

          this.getPrincipal();
        }, () => {
          message.error('删除失败');
        });
      },
    });
  }

  getPrincipal = () => {
    axios.get(`/principal`).then((res) => {
      const da = res.data;

      this.setState({
        dataSource: res.data,
      })
    })
  }

  handleOk = () => {
    let fn = null;
    let params: any = {};
    
    if (this.state.principalModal === 1) {
      fn = axios.post;
    } else {
      fn = axios.put;
      params._id = this.state.modifyId;
    }

    fn(`/principal`, {
      date: new Date(this.state.date.format().substring(0, 10)),
      variation: this.state.variation,
      remark: this.state.remark,
      ...params,
    }).then((res) => {
      message.success('操作成功');
      this.getPrincipal();
    })

    this.handleCancel();
  }

  handleCancel = () => {
    this.setState({
      principalModal: 0,
    })
  }

  changeInput = (name, val) => {
    this.setState({
      [name]: val
    });
  }

  componentDidMount() {
    this.getPrincipal();
  }

  render() {
    return (
      <div className='userinfo'>
        <Tabs activeKey={ this.state.activeKey } onChange={ this.changeTab }>
          <TabPane tab="投资总额" key="1">
            <Row gutter={ 16 }>
              <Col span={ 2 } offset={ 22 }>
                <Button onClick={ this.addNew }>新增</Button>
              </Col>
            </Row>
            <br />
            <Table
              pagination={{ current: this.state.page, pageSize: this.state.pageSize, total: this.state.total, onChange: this.paginationChange }}
              bordered={ true }
              dataSource={ this.state.dataSource }
              columns={ this.state.columns }
              rowKey={ item => item._id }
            />
            {
              !!this.state.principalModal && <Modal
                title={ this.state.principalModal === 1 ? '新增' : '编辑'}
                visible={ !!this.state.principalModal }
                onOk={ this.handleOk }
                onCancel={ this.handleCancel }
                width="840px"
              >
                <Row gutter={ 16 }>
                  <Col span={ 3 } className='must'>时间：</Col>
                  <Col span={ 9 }>
                    <DatePicker
                      style={{ width: '100%' }}
                      value={ this.state.date }
                      onChange={ (value) => { this.changeInput('date', value) }}
                    />
                  </Col>
                  <Col span={ 3 } className='must'>本金增减：</Col>
                  <Col span={ 9 }>
                    <Input value={ this.state.variation } onChange={ (e) => { this.changeInput('variation', e.target.value) }} />
                  </Col>
                </Row>
                <br />
                <Row gutter={ 16 }>
                  <Col span={ 3 }>备注：</Col>
                  <Col span={ 21 }>
                    <TextArea value={ this.state.remark } onChange={ (e) => { this.changeInput('remark', e.target.value) }} />
                  </Col>
                </Row>
                <br />
              </Modal>
            }
          </TabPane>
          <TabPane tab="证券帐号" key="2">
            {
              this.state.mode === 0 && <Account
                name={ this.props.userStore.name }
                stockAccounts={ this.props.userStore.stockAccounts }
                goToDetail={ this.goToDetail}
              />
            }
            {
              this.state.mode === 1 && <AccountDetail
                goBack={ this.goBack }
              />
            }
          </TabPane>
        </Tabs>
      </div>
    )
  }
}

export default UserInfo;
