import React from 'react';
import { Table, Select, Row, Col, Button, Modal, Input, DatePicker, message, InputNumber, Descriptions } from 'antd';
import axios from 'axios';

import './Debt.scss';

class Debt extends React.PureComponent {
  state = {
    columns: [{
      title: '开始时间',
      dataIndex: 'date',
      key: 'date',
      render: (value) => {
        return value.substring(0, 10)
      }
    }, {
      title: '负债',
      dataIndex: 'debt',
      key: 'debt'
    }, {
      title: '年华利息',
      dataIndex: 'debtRate',
      key: 'debtRate'
    }, {
      title: '共计利息',
      dataIndex: 'interests',
      key: 'interests'
    }, {
      title: '备注',
      dataIndex: 'remark',
      key: 'remark'
    }],
    page: 1,
    pageSize: 20,
    total: 0,
    dataSource: [],
  };

  paginationChange = (page, pageSize) => {
    this.setState({
      page,
      pageSize,
    }, () => {
      this.getDebt();
    })
  }

  getDebt = () => {}

  render () {
    return <div>
      
      <Table
        pagination={{ current: this.state.page, pageSize: this.state.pageSize, total: this.state.total, onChange: this.paginationChange }}
        bordered={ true }
        dataSource={ this.state.dataSource }
        columns={ this.state.columns }
        rowKey={ item => item._id }
      />
    </div>
  }
}

export default Debt;
