import React from 'react';
import { Form, Input, Button, message, Checkbox } from 'antd';
import { createHashHistory } from 'history';
import axios from 'axios';
import {
  LockOutlined,
  UserOutlined,
} from '@ant-design/icons';

import './Login.scss';

const history = createHashHistory();

class Login extends React.PureComponent {
  onFinish = values => {
    if (values.username.length > 0 && values.password.length > 0) {
      axios.post('/login', {
        ...values
      }).then((res: any) => {
        // if (res.code === 200) {
          localStorage.setItem('token', res.data.token);
  
          history.push('/home');
          message.success('登录成功');
        // } else {
        //   this.loginError();
        // }
      }, (res: any) => {
        this.loginError(res.response.data.msg);
      })
    }
  }

  loginError = (msg: string) => {
    message.error(msg);
  }

  onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  }

  render() {
    return (
      <div className='login'>
        <div className='login-box'>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={ this.onFinish }
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: 'Please input your Username!' }]}
          >
            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your Password!' }]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            {/* <a className="login-form-forgot" href="">
              Forgot password
            </a> */}
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button">
              Log in
            </Button>
            {/* Or <a href="">register now!</a> */}
          </Form.Item>
        </Form>
        </div>
      </div>
    )
  }
}

export default Login;
