import React from 'react';
import { Layout, Menu } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  TableOutlined,
  MenuOutlined,
  GiftOutlined,
  SettingOutlined,
  AreaChartOutlined,
  PayCircleOutlined,
  FireOutlined,
  InsuranceOutlined,
  FileImageOutlined,
  ReadOutlined,
  WalletOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom'
import { inject } from 'mobx-react';

import './Overall.scss'

interface IProps {
  userStore ?: any
}
interface IState {
  collapsed: boolean
}


const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;

@inject((allStores: any) => {
  return {
    userStore: allStores.userStore
  }
})
class Overall extends React.PureComponent<IProps, IState> {
  state = {
    collapsed: !!(window.localStorage && window.localStorage.getItem('collapsed')),
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    }, () => {
      window.localStorage && window.localStorage.setItem('collapsed', this.state.collapsed ? '1' : '');
    });
  };

  componentDidMount() {
    // 初始化一些数据
    this.props.userStore!.getAccount();
  }

  render() {
    return (
      <Layout>
        <Sider trigger={ null } collapsible collapsed={ this.state.collapsed } 
          style={{
            overflow: 'auto',
            height: '100vh',
          }}>
          <div className="logo" />
          <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
            <Menu.Item key="1" icon={ <MenuOutlined /> }>
              <Link to='/home'>首页</Link>
            </Menu.Item>
            <Menu.Item key="2" icon={ <UserOutlined /> }>
              <Link to='/userInfo'>账户信息</Link>
            </Menu.Item>
            <Menu.Item key="10" icon={ <AreaChartOutlined /> }>
              <Link to='/board'>数据看板</Link>
            </Menu.Item>
            <Menu.Item key="100" icon={<TableOutlined />}>
              <Link to='/grid'>网格</Link>
            </Menu.Item>
            <Menu.Item key="4" icon={ <PayCircleOutlined /> }>
              <Link to='/daily'>每日资产</Link>
            </Menu.Item>
            <Menu.Item key="5" icon={ <GiftOutlined /> }>
              <Link to='/lottery'>彩票</Link>
            </Menu.Item>
            <SubMenu key="sub3" icon={ <FireOutlined /> } title="打新">
              <Menu.Item key="3.1">
                <Link to='/new/stock'>新股</Link>
              </Menu.Item>
              <Menu.Item key="3.2">
                <Link to='/new/bond'>新债</Link>
              </Menu.Item>
            </SubMenu>
            <Menu.Item key="6" icon={ <WalletOutlined /> }>
              <Link to='/bonus'>分红</Link>
            </Menu.Item>
            <Menu.Item key="7" icon={ <InsuranceOutlined /> }>
              <Link to='/aged'>养老计划</Link>
            </Menu.Item>
            {/* <Menu.Item key="15" icon={ <FrownOutlined /> }>
              <Link to='/debt'>负债</Link>
            </Menu.Item> */}
            <Menu.Item key="16" icon={ <FileImageOutlined /> }>
              <Link to='/uploader'>床图</Link>
            </Menu.Item>
            <SubMenu key="setting" icon={ <SettingOutlined /> } title="设置">
              <Menu.Item key="66">
                <Link to='/setting/shanghai'>沪市</Link>
              </Menu.Item>
              <Menu.Item key="8">
                <Link to='/setting/shenzhen'>深市</Link>
              </Menu.Item>
              <Menu.Item key="9">
                <Link to='/setting/backup'>数据备份</Link>
              </Menu.Item>
              <Menu.Item key="setting004">
                <Link to='/setting/log'>日志</Link>
              </Menu.Item>
              <Menu.Item key="/exRate">
                <Link to='/setting/exRate'>汇率</Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu key="blog" icon={ <ReadOutlined /> } title="博客">
              {/* <Menu.Item key="blog001">
                <Link to='/blog/visit'>用户访问</Link>
              </Menu.Item> */}
              <Menu.Item key="blog002">
                <Link to='/blog/view'>文章浏览量</Link>
              </Menu.Item>
              <Menu.Item key="blog003">
                <Link to='/setting/comment'>评论</Link>
              </Menu.Item>
            </SubMenu>
          </Menu>
        </Sider>
        <Layout className="site-layout" >
          <Header className="site-layout-background" style={{ paddingLeft: 20 }}>
            {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'trigger',
              onClick: this.toggle,
            })}
          </Header>
          <Content
            className="site-layout-background"
            style={{
              margin: '24px 16px',
              padding: 24,
              minHeight: 280,
              overflow: 'auto',
              height: 0
            }}
          >
            {
              this.props.children
            }
          </Content>
        </Layout>
      </Layout>
    );
  }
};

export default Overall;
