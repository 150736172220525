import React from 'react';
import axios from 'axios';
import Request from '@/utils/request';
import { Statistic, Row, Col, Button, Card, Progress } from 'antd';
import moment from 'moment';
import { getKeyFromDate } from '@/utils/tools';
import {
  PercentageOutlined,
  PayCircleOutlined,
} from '@ant-design/icons';
import { sortedIndexBy } from 'lodash-es';

import './Home.scss';

interface IProps {
  userStore: any
}
interface IState {}

class Home extends React.PureComponent<IProps, IState> {
  state = {
    dailyRank: [],
    gridRank: [],
    newBondRank: [],

    dailyRankLoading: true,
    gridRankLoading: true,
    newBondRankLoading: true,

    dailyEarnLoading: true,
    dailyLossLoading: true,
    dailyEarnType: 'num', // 'num' || 'rate'
    dailyEarn: [],
    dailyLossType: 'num', // 'num' || 'rate'
    dailyLoss: [],
  }

  dailyAssetData = []

  changeDailyEarnType = () => {
    const result = this.dailyAssetData;
    const key = this.state.dailyEarnType === 'num' ? 'prevRate' : 'prevEarn'; 

    result.sort((v1, v2) => {
      return v2[key] - v1[key];
    });

    this.setState({
      dailyEarnType: this.state.dailyEarnType === 'num' ? 'rate' : 'num',
      dailyEarn: result.slice(0, 10),
    })
  }

  changeDailyLossType = () => {
    const result = this.dailyAssetData;
    const key = this.state.dailyLossType === 'num' ? 'prevRate' : 'prevEarn'; 

    result.sort((v1, v2) => {
      return v1[key] - v2[key];
    });

    this.setState({
      dailyLossType: this.state.dailyLossType === 'num' ? 'rate' : 'num',
      dailyLoss: result.slice(0, 10),
    })
  }

  componentDidMount() {
    axios.get('/rank/dailyAsset').then((res) => {
      this.setState({
        dailyRank: res.data,
        dailyRankLoading: false,
      });
    });

    axios.get(`/gridDaily/top?num=10`).then((res) => {
      // let allData = [];
      // let dateMap = {};

      // res.data.forEach((item) => {
      //   const key = moment(item.date).format('YYYY-MM-DD');

      //   if (dateMap[key] === undefined) {
      //     allData.push({
      //       date: key,
      //       profit: 0
      //     })

      //     dateMap[key] = allData.length - 1;
      //   }

      //   allData[dateMap[key]].profit += Number(item.profit);
      //   allData[dateMap[key]]._id = item._id;
      // });

      // allData.sort((v1, v2) => {
      //   return v2.profit - v1.profit;
      // });
      this.setState({
        gridRank: res.data,
        gridRankLoading: false,
      });
    });

    axios.get(`/rank/newBond`).then((res) => {
      this.setState({
        newBondRank: res.data,
        newBondRankLoading: false,
      });
    });

    axios.get(`/dailyAsset?page=1&pageSize=1000000`).then((res) => {
      this.dailyAssetData = res.data;
      const result = res.data;

      result.forEach((item) => {
        item.date = moment(item.date).format('YYYY-MM-DD')
        item.prevRate = (item.prevRate * 100).toFixed(2)
      })

      result.sort((v1, v2) => {
        return v2.prevEarn - v1.prevEarn;
      });

      const dailyEarnTeen = result.slice(0, 10);
      const dailyLossTeen = result.reverse().slice(0, 10);
      let earnTeenAll = 0;
      dailyEarnTeen.forEach((item) => {
        earnTeenAll += (+item.prevEarn);
      });
      dailyEarnTeen.push({
        date: '总计',
        prevEarn: earnTeenAll.toFixed(2),
      });
      let dailyLossAll = 0;
      dailyLossTeen.forEach((item) => {
        dailyLossAll += (+item.prevEarn);
      });
      dailyLossTeen.push({
        date: '总计',
        prevEarn: dailyLossAll.toFixed(2),
      });

      this.setState({
        dailyEarnLoading: false,
        dailyLossLoading: false,
        dailyEarn: dailyEarnTeen,
        dailyLoss: dailyLossTeen,
      })
    })
  }

  render() {
    return (
      <React.Fragment>
      <Row gutter={ 16 }>
        <Col md={ 8 } xs={ 24 }>
          <Card title="每日净值排行榜" loading={ this.state.dailyRankLoading }>
            {
              this.state.dailyRank.map((item) => {
                return (
                  <p className='home-list-row' key={ item._id }>
                    <span>{ item.date.substring(0, 10) }</span>
                    <span>{ item.total }</span>
                  </p>
                )
              })
            }
          </Card>
        </Col>
        <Col md={ 8 } xs={ 24 }>
          <Card title="网格收益排行榜" loading={ this.state.gridRankLoading }>
            {
              this.state.gridRank.map((item) => {
                return (
                  <p className='home-list-row' key={ item._id }>
                    <span>{ item.date.substring(0, 10) }</span>
                    <span>{ item.profit }</span>
                  </p>
                )
              })
            }
          </Card>
        </Col>
        <Col md={ 8 } xs={ 24 }>
          <Card title="新债收益率排行榜" loading={ this.state.newBondRankLoading }>
            {
              this.state.newBondRank.map((item) => {
                return (
                  <p className='home-list-row' key={ item._id }>
                    <span>{ item.date.substring(0, 10) }</span>
                    <span>{ item.newName }</span>
                    <span>{ (item.yield * 100).toFixed(2) }%</span>
                  </p>
                )
              })
            }
          </Card>
        </Col>
      </Row>
      <Row gutter={ 16 } className='mt-30'>
        <Col md={ 8 } xs={ 24 }>
          <Card title="单日净值收益排行榜" loading={ this.state.dailyEarnLoading } extra={
            <span className='type-change' onClick={ this.changeDailyEarnType }>
              {
                this.state.dailyEarnType === 'num' ? <PercentageOutlined /> : <PayCircleOutlined />
              }
            </span>
          }>
            {
              this.state.dailyEarn.map((item) => {
                return (
                  <p className='home-list-row' key={ item.date + this.state.dailyEarnType }>
                    <span>{ item.date }</span>
                    <span>{ this.state.dailyEarnType === 'num' ? item.prevEarn : `${ item.prevRate }%` }</span>
                  </p>
                )
              })
            }
          </Card>
        </Col>
        <Col md={ 8 } xs={ 24 }>
          <Card title="单日净值亏损排行榜" loading={ this.state.dailyLossLoading } extra={
            <span className='type-change' onClick={ this.changeDailyLossType }>
              {
                this.state.dailyLossType === 'num' ? <PercentageOutlined /> : <PayCircleOutlined />
              }
            </span>
          }>
            {
              this.state.dailyLoss.map((item) => {
                return (
                  <p className='home-list-row' key={ item.date + this.state.dailyLossType }>
                    <span>{ item.date }</span>
                    <span>{ this.state.dailyLossType === 'num' ? item.prevEarn : `${ item.prevRate }%` }</span>
                  </p>
                )
              })
            }
          </Card>
        </Col>
      </Row>
      </React.Fragment>
    )
  }
}

export default Home;
