export const dailyEarnChartConfig = {
  tooltip: {
    trigger: 'axis'
  },
  legend: {
    data: ['截止收益']
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: []
  },
  yAxis: {
    type: 'value'
  },
  series: [
    {
      name: '截止收益',
      type: 'line',
      stack: 'Total',
      data: [],

      markPoint: {
        data: [
          {
            type: 'max',
            name: '最大值',
            label: {
              color: '#000'
            },
            itemStyle: {
              color: 'rgba(255, 158, 68, 0.5)'
            }
          },
          {
            type: 'min',
            name: '最小值',
            label: {
              color: '#000',
              // position: 'bottom'
            },
            itemStyle: {
              color: 'rgba(255, 158, 68, 0.5)'
            }
          }
        ]
      },
      markLine: {
        symbol: "none", //去掉警戒线最后面的箭头
        data: [
          {
            silent: false, //鼠标悬停事件  true没有，false有
            lineStyle: {
              //警戒线的样式  ，虚实  颜色
              type: "dashed",
              color: "#1890ff",
            },
            yAxis: "50000",
          }
        ]
      },
    }
  ]
};

