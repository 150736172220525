import React from 'react';
import { Table, Col, Row, Button, Modal, Input, Checkbox, message, DatePicker, Descriptions, Select } from 'antd';
import axios from 'axios';
import moment from 'moment';
import {
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { toJS } from 'mobx';
import { observer, inject } from 'mobx-react';

import { round2 } from '@/utils/tools';

import './Bond.scss'

const { confirm } = Modal;
const { Option } = Select;

interface IProps {
  userStore: any
}
interface IState {}

@inject('userStore')
@observer
class Bond extends React.PureComponent<IProps, IState>  {
  state = {
    columns: [{
      title: '中签时间',
      dataIndex: 'date',
      key: 'date',
      render: (value) => {
        return value.substring(0, 10)
      }
    }, {
      title: '新债名称',
      dataIndex: 'newName',
      key: 'newName'
    }, {
      title: '拥有者',
      dataIndex: 'belongTo',
      key: 'belongTo',
      render: (value) => {
        return this.accountMap[value];
      }
    }, {
      title: '新债收益',
      dataIndex: 'newBonus',
      key: 'newBonus'
    }, {
      title: '新债缴费',
      dataIndex: 'ballotFund',
      key: 'ballotFund'
    }, {
      title: '新债收益率',
      dataIndex: 'yield',
      key: 'yield',
      render: (value) => {
        return (value * 100).toFixed(4) + '%';
      }
    }, {
      title: '操作',
      width: '130px',
      render: (val, recorder) => {
        return (
          <React.Fragment>
            <span className='table-warning' onClick={ () => { this.modifyeNew(recorder) } }>编辑</span>
            <span className='table-delete' onClick={ () => { this.deleteNew(recorder._id) } }>删除</span>
          </React.Fragment>
        )
      }
    }],
    dataSource: [],
    newModal: 0,
    stockAccounts: [],
    newName: '',
    date: moment(`${ (new Date()).getFullYear() }-${ (new Date()).getMonth() + 1 }-${ (new Date()).getDate() }`, 'YYYY-MM-DD'),
    accountPro: {},
    allProfit: 0,
    page: 1,
    pageSize: 20,
    total: 0,

    modifyId: '',
    belongTo: '',
    newBonus: '',
    ballotFund: '',
    stockMap: {},
    selectYear: (new Date()).getFullYear(),
    gridYear: [],
    selectAccount: '',
  }
  accountMap = {}

  deleteNew = (id) => {
    confirm({
      title: 'Are you sure delete this task?',
      icon: <ExclamationCircleOutlined />,
      content: '确定删除？',
      okText: '确定',
      okType: 'danger',
      cancelText: '取消',
      onOk: () => {
        axios.delete(`/new/bond?_id=${ id }`).then((res) => {
          message.success('删除成功');

          this.getNewBond();
        }, () => {
          message.error('删除失败');
        });
      },
    });
  }

  modifyeNew = (rec) => {
    const stockAccounts = this.state.stockAccounts
    const gotAccount = {}
    let newName = ''

    Object.keys(rec).forEach((title) => {
      const matches = /^(\d+)name$/.exec(title)

      if (matches && !gotAccount.hasOwnProperty(matches[1])) {
        gotAccount[matches[1]] = rec[`${ matches[1] }value`]
        newName = rec[`${ matches[1] }name`]
      }
    })

    stockAccounts.forEach((account) => {
      if (gotAccount.hasOwnProperty(account.accountId)) {
        account.got = true
        account.earning = (gotAccount[account.accountId] || '') + ''
      }
    })

    this.setState({
      newModal: 2,
      modifyId: rec._id,
      date: moment(rec.date.substring(0, 10), 'YYYY-MM-DD'),
      newName: rec.newName,
      newBonus: rec.newBonus,
      ballotFund: rec.ballotFund,
      belongTo: rec.belongTo,
    })
  }

  changeInput = (name, val) => {
    this.setState({
      [name]: val
    });
  }

  getNewBond = () => {
    axios.get(`/new/bond?year=${ this.state.selectYear }&page=${ this.state.page }&pageSize=${ this.state.pageSize }&selectAccount=${ this.state.selectAccount }`).then(((res: any) => {
      const ori = res.data
      let allP = 0;

      ori.forEach((tick) => {
        allP += (+tick.newBonus);
      });

      this.setState({
        allPro: allP,
        dataSource: ori,
        allProfit: res.allProfit,

        page: res.page.page,
        pageSize: res.page.pageSize,
        total: res.page.total,
      });
    }))
  }

  handleChange = (pos, key, value) => {
    const stockAccounts = this.state.stockAccounts

    stockAccounts[pos][key] = value

    this.setState({ stockAccounts: [...stockAccounts] })
  }

  addNew = () => {
    this.setState({
      newModal: 1,
      newName: '',
      newBonus: '',
      ballotFund: '',
      belongTo: '',
    })
  }

  paginationChange = (page, pageSize) => {
    this.setState({
      page,
      pageSize,
    }, () => {
      this.getNewBond();
    })
  }

  searchBond = () => {
    this.getNewBond();
  }

  handleOk = () => {
    if (!this.state.newName || !this.state.newBonus || !this.state.date) {
      message.error('输入')

      return
    }
    const info: any =  {
      date: new Date(this.state.date.format().substring(0, 10)),
      newName: this.state.newName,
      newBonus: this.state.newBonus,
      ballotFund: this.state.ballotFund,
      belongTo: this.state.belongTo,
    }
    let action

    if (this.state.newModal === 1) {
      action = axios.post
    } else {
      action = axios.put
      info._id = this.state.modifyId
    }

    action('/new/bond', info).then((res) => {
      message.success('操作成功');
      this.getNewBond();
      this.clearAddData();
    }, () => {
      message.error('操作失败');
    })

    this.handleCancel();
  }

  clearAddData = () => {
    const stockAccounts = this.state.stockAccounts

    stockAccounts.forEach((acc) => {
      acc.got = false
      acc.earning = ''
    })

    this.setState({
      stockAccounts: [...stockAccounts],
      newName: ''
    })
  }

  handleCancel = () => {
    this.setState({ newModal: 0 });
    this.clearAddData();
  }

  // 初始化时间
  initYear = () => {
    let thisYear = (new Date()).getFullYear();
    let years = [];

    new Array(thisYear - 2019).fill('1').forEach((item, ind) => {
      years.push(2019 + ind + 1);
    });

    this.setState({
      gridYear: years,
    });
  }

  componentDidMount() {
    this.initYear();
    this.getNewBond();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      stockAccounts: toJS(nextProps.accountMap).stockAccounts
    })
  }

  render() {
    const stockAccounts = toJS(this.props.userStore).stockAccounts || [];

    stockAccounts.forEach((item) => {
      this.accountMap[item._id] = item.belong;
    })

    return (
      <div className='new-tick'>
        <Row gutter={ 16 }>
          <Col span={ 4 }>
            <Select value={ this.state.selectYear } style={{ width: '100%' }} onChange={ (value) => { this.changeInput('selectYear', value) } }>
              <Option value="">全部</Option>
              {
                this.state.gridYear.map((item) => {
                  return (
                    <Option value={ item } key={ item }>{ item }年</Option>
                  )
                })
              }
            </Select>
          </Col>
          <Col span={ 4 }>
            <Select value={ this.state.selectAccount } style={{ width: '100%' }} onChange={ (value) => { this.changeInput('selectAccount', value) } }>
              <Option value="">全部</Option>
              {
                stockAccounts.filter((item) => {
                  return item.hasNewBond;
                }).map((item) => {
                  return <Option value={ item._id } key={ item._id }>{ item.business }{ item.uid }({ item.belong })</Option>
                })
              }
            </Select>
          </Col>
          <Col span={ 2 } offset={ 12 }>
            <Button type='primary' onClick={ this.searchBond }>搜索</Button>
          </Col>
          <Col span={ 2 }>
            <Button type='default' onClick={ this.addNew }>新增</Button>
          </Col>
        </Row>
        <br />
        <Descriptions
          bordered
        >
          <Descriptions.Item label="总收益">{ round2(this.state.allProfit) }</Descriptions.Item>
          {
            Object.keys(this.state.accountPro).map((id) => {
              return (
                <Descriptions.Item label={ this.accountMap[id] } key={ id }>{ round2(this.state.accountPro[id]) }</Descriptions.Item>
              )
            })
          }
        </Descriptions>
        <br />
        <Table
          bordered={ true }
          pagination={{ current: this.state.page, pageSize: this.state.pageSize, total: this.state.total, onChange: this.paginationChange }}
          dataSource={ this.state.dataSource }
          columns={ this.state.columns }
          rowKey={ item => item._id }
        />
        {
          !!this.state.newModal && <Modal
            title={ this.state.newModal === 1 ? '新增' : '编辑' }
            visible={ !!this.state.newModal }
            onOk={ this.handleOk }
            onCancel={ this.handleCancel }
            width="840px"
          >
            <Row className='account-row'>
              <Col span={ 4 } className='must'>时间：</Col>
              <Col span={ 8 }>
                <DatePicker
                  style={{ width: '100%' }}
                  value={ this.state.date }
                  onChange={ (value) => { this.setState({ date: value })} }
                />
              </Col>
              <Col span={ 4 } className='must'>新债名称：</Col>
              <Col span={ 8 }>
                <Input value={ this.state.newName } onChange={ (e) => { this.setState({ newName: e.target.value }) } } />
              </Col>
            </Row>
            <Row className='account-row'>
              <Col span={ 4 } className='must'>新债收益：</Col>
              <Col span={ 8 }>
                <Input value={ this.state.newBonus } onChange={ (e) => { this.setState({ newBonus: e.target.value }) } } />
              </Col>
              <Col span={ 4 } className='must'>新债归属：</Col>
              <Col span={ 8 }>
                <Select style={{ width: '100%' }} value={ this.state.belongTo } onChange={ (v) => { this.setState({belongTo: v}) }}>
                  {
                    stockAccounts.filter((item) => {
                      return item.hasNewBond;
                    }).map((item) => {
                      return <Option value={ item._id } key={ item._id }>{ item.business }{ item.uid }({ item.belong })</Option>
                    })
                  }
                </Select>
              </Col>
            </Row>
            <Row className='account-row'>
              <Col span={ 4 } className='must'>新债缴费：</Col>
              <Col span={ 8 }>
                <Input value={ this.state.ballotFund } onChange={ (e) => { this.setState({ ballotFund: e.target.value }) } } />
              </Col>
            </Row>
            {
              this.state.stockAccounts.map((acc, ind) => {
                return (
                  <Row className='account-row' key={ acc.accountId }>
                    <Col span={ 1 }>
                      <Checkbox checked={ acc.got } onChange={ (e) => { this.handleChange(ind, 'got', e.target.checked ) } } />
                    </Col>
                    <Col span={ 4 }>{ acc.securitiesCompany + acc.accountId }</Col>
                    <Col span={ 2 }></Col>
                    <Col span={ 12 }>
                      <Input value={ acc.earning } onChange={ (e) => { this.handleChange(ind, 'earning', e.target.value ) } } disabled={ !acc.got } />
                    </Col>
                  </Row>
                )
              })
            }
          </Modal>
        }
      </div>
    )
  }
}

export default Bond;
