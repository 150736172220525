import React from 'react';
import { Table, Select, Row, Col, Button, Modal, Input, DatePicker, message, InputNumber, Descriptions } from 'antd';
import axios from 'axios';
import moment from 'moment';
import {
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { observer, inject } from 'mobx-react';

import { percentage, round2 } from '../../utils/tools';

import './Daily.scss'

const { Option } = Select;
const { confirm } = Modal;

interface IProps {
  userStore: any
}
interface IState {}

@inject('userStore')
@observer
class Grid extends React.PureComponent<IProps, IState> {
  state = {
    dataSource: [],
    columns: [{
      title: '时间',
      dataIndex: 'date',
      key: 'date',
      width: 120,
      fixed: 'left' as 'left',
      render: (value) => {
        return value.substring(0, 10)
      }
    }, {
      title: '人名币总资产',
      dataIndex: 'rmbTotal',
      key: 'rmbTotal',
    }, {
      title: '人名币负债',
      dataIndex: 'rmbDebt',
      key: 'rmbDebt'
    }, {
      title: '人名币净资产',
      dataIndex: 'rmbNet',
      key: 'rmbNet'
    }, {
      title: '港币总资产',
      dataIndex: 'hkdTotal',
      key: 'hkdTotal'
    }, {
      title: '港币负债',
      dataIndex: 'hkdDebt',
      key: 'hkdDebt'
    }, {
      title: '港币净资产',
      dataIndex: 'hkdNet',
      key: 'hkdNet'
    }, {
      title: '美元总资产',
      dataIndex: 'usdTotal',
      key: 'usdTotal'
    }, {
      title: '美元负债',
      dataIndex: 'usdDebt',
      key: 'usdDebt'
    }, {
      title: '美元净资产',
      dataIndex: 'usdNet',
      key: 'usdNet'
    }, {
      title: '总净资产(RMB)',
      dataIndex: 'total',
      key: 'total',
      render: (value) => {
        return (value - 0).toFixed(2);
      }
    }, {
      title: '环比收益',
      dataIndex: 'prevEarn',
      key: 'prevEarn',
    }, {
      title: '环比收益率',
      dataIndex: 'prevRate',
      key: 'prevRate',
      render: (value) => {
        return value !== undefined ? ((value * 100).toFixed(2) + '%') : '';
      }
    }, {
      title: '截止收益',
      dataIndex: 'cutOffEarn',
      key: 'cutOffEarn',
    }, {
      title: '截止收益率',
      dataIndex: 'cutOffRate',
      key: 'cutOffRate',
      render: (value) => {
        return value !== undefined ? ((value * 100).toFixed(2) + '%') : '';
      }
    }, {
      title: '操作',
      width: '130px',
      fixed: 'right' as 'right',
      render: (val, recorder) => {
        return (
          <React.Fragment>
            <span className='table-warning' onClick={ () => { this.modifyDaily(recorder) } }>编辑</span>
            <span className='table-delete' onClick={ () => { this.deleteDaily(recorder._id) } }>删除</span>
          </React.Fragment>
        )
      }
    }],
    selectAccount: '',
    selectYear: (new Date()).getFullYear() + '',
    selectMonth: (new Date()).getMonth() + 1 + '',
    addModal: 0, // 0 表示关闭，1 表示增加状态 2 表示编辑状态
    stockAccounts: [], // 总的券商账户信息
    stockBelong: [], // 对应账号下所持有的股票

    rmbTotal: '',
    rmbDebt: '',
    hkdTotal: '',
    hkdDebt: '',
    usdTotal: '',
    usdDebt: '',

    page: 1,
    pageSize: 20,
    total: 0,

    date: moment(`${ (new Date()).getFullYear() }-${ (new Date()).getMonth() + 1 }-${ (new Date()).getDate() }`, 'YYYY-MM-DD'),
    allEarnings: 0,
    times: 1,
    cost: 0,
    bugAll: 0,
    sellAll: 0,
    modifyId: ''
  }

  deleteDaily = (id) => {
    confirm({
      title: 'Are you sure delete this task?',
      icon: <ExclamationCircleOutlined />,
      content: '确定删除？',
      okText: '确定',
      okType: 'danger',
      cancelText: '取消',
      onOk: () => {
        axios.delete(`/dailyAsset?id=${ id }`).then((res) => {
          message.success('删除成功');

          this.getDaily();
        }, () => {
          message.error('删除失败');
        });
      },
    });
  }

  modifyDaily = (rec) => {
    this.setState({
      modifyId: rec._id,

      rmbTotal: rec.rmbTotal,
      rmbDebt: rec.rmbDebt,
      hkdTotal: rec.hkdTotal,
      hkdDebt: rec.hkdDebt,
      usdTotal: rec.usdTotal,
      usdDebt: rec.usdDebt,

      date: moment(rec.date.substring(0, 10), 'YYYY-MM-DD'),
      addModal: 2
    })
  }

  changeInput = (name, val) => {
    this.setState({
      [name]: val
    }, () => {
    });

  }

  addNewDaily = () => {
    this.setState({
      addModal: 1,
      rmbTotal: '',
      rmbDebt: '',
      hkdTotal: '',
      hkdDebt: '',
      usdTotal: '',
      usdDebt: '',
      date: moment(`${ (new Date()).getFullYear() }-${ (new Date()).getMonth() + 1 }-${ (new Date()).getDate() }`, 'YYYY-MM-DD'),
    })
  }

  paginationChange = (page, pageSize) => {
    this.setState({
      page,
      pageSize,
    }, () => {
      this.getDaily();
    })
  }

  handleOk = () => {
    let flag = false;

    ;['date', 'rmbTotal', 'rmbDebt', 'hkdTotal', 'hkdDebt', 'usdTotal', 'usdDebt'].forEach((item) => {
      if (this.state[item] !== '' && isNaN(Number(this.state[item]))) {
        flag = true;
      }
    });

    if (flag) {
      message.warning('输入错误');

      return;
    }

    const info: any = {
      rmbTotal: this.state.rmbTotal || '0',
      rmbDebt: this.state.rmbDebt || '0',
      hkdTotal: this.state.hkdTotal || '0',
      hkdDebt: this.state.hkdDebt || '0',
      usdTotal: this.state.usdTotal || '0',
      usdDebt: this.state.usdDebt || '0',
      date: new Date(this.state.date.format().substring(0, 10))
    }
    let action

    if (this.state.addModal === 1) {
      action = axios.post
    } else {
      action = axios.put
      info.id = this.state.modifyId
    }

    action('/dailyAsset', info).then((res) => {
      message.success('操作成功');
      this.getDaily();

      // 恢复默认
      this.setState({
        buy: '',
        sell: '',
        num: 100
      })
    }, () => {
      message.error('操作失败');
    })

    this.handleCancel()
  }

  handleCancel = () => {
    this.setState({ addModal: 0 })
  }

  getDaily = () => {
    axios.get(`/dailyAsset`, {
      params: {
        page: this.state.page,
        pageSize: this.state.pageSize,
      }
    }).then((res: any) => {

      this.setState({
        dataSource: res.data,
        page: res.page.page,
        pageSize: res.page.pageSize,
        total: res.page.total,
      });
    })
  }

  componentDidMount() {
    this.getDaily();
  }

  render() {
    return (
      <div className='daily'>
        <Row>
          <Col span={ 2 } offset={ 22 }>
            <Button type='primary' onClick={ this.addNewDaily }>新增</Button>
          </Col>
        </Row>
        <br />
        <Table
          bordered={ true }
          sticky={ true }
          dataSource={ this.state.dataSource }
          pagination={{ current: this.state.page, pageSize: this.state.pageSize, total: this.state.total, onChange: this.paginationChange }}
          columns={ this.state.columns }
          scroll={{ x: 2100 }}
          rowKey={ item => item._id }
        />
        {
          !!this.state.addModal && <Modal
            title={ this.state.addModal === 1 ? '新增' : '编辑'}
            visible={ !!this.state.addModal }
            onOk={ this.handleOk }
            onCancel={ this.handleCancel }
            width="840px"
          >
            <Row gutter={ 16 }>
              <Col span={ 4 } className='must'>时间：</Col>
              <Col span={ 8 }>
                <DatePicker
                  style={{ width: '100%' }}
                  value={ this.state.date }
                  onChange={ (value) => { this.changeInput('date', value) }}
                />
              </Col>
            </Row>
            <br />
            <Row gutter={ 16 }>
              <Col span={ 4 } className='must'>人名币总资产</Col>
              <Col span={ 8 }>
                <Input value={ this.state.rmbTotal } onChange={ (e) => { this.changeInput('rmbTotal', e.target.value) }} />
              </Col>
              <Col span={ 4 } className='must'>人名币负债</Col>
              <Col span={ 8 }>
                <Input value={ this.state.rmbDebt } onChange={ (e) => { this.changeInput('rmbDebt', e.target.value) }} />
              </Col>
            </Row>
            <br />
            <Row gutter={ 16 }>
              <Col span={ 4 } className='must'>港币总资产</Col>
              <Col span={ 8 }>
                <Input value={ this.state.hkdTotal } onChange={ (e) => { this.changeInput('hkdTotal', e.target.value) }} />
              </Col>
              <Col span={ 4 } className='must'>港币负债</Col>
              <Col span={ 8 }>
                <Input value={ this.state.hkdDebt } onChange={ (e) => { this.changeInput('hkdDebt', e.target.value) }} />
              </Col>
            </Row>
            <br />
            <Row gutter={ 16 }>
              <Col span={ 4 } className='must'>美元总资产</Col>
              <Col span={ 8 }>
                <Input value={ this.state.usdTotal } onChange={ (e) => { this.changeInput('usdTotal', e.target.value) }} />
              </Col>
              <Col span={ 4 } className='must'>美元负债</Col>
              <Col span={ 8 }>
                <Input value={ this.state.usdDebt } onChange={ (e) => { this.changeInput('usdDebt', e.target.value) }} />
              </Col>
            </Row>
            <br />
          </Modal>
        }
      </div>
    )
  }
}

export default Grid;
