import React from 'react';
import Router from '@/router/router';
import { Provider } from "mobx-react";
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import stores from "./store";


function App() {
  return (
    <div className="App">
      <Provider { ...stores }>
        <ConfigProvider locale={ zhCN }>
          <Router />
        </ConfigProvider>
      </Provider>
    </div>
  );
}

export default App;
