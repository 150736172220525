import React from 'react';
import { Table, Select, Row, Col, Button, Modal, Input, DatePicker, message, InputNumber, Descriptions } from 'antd';
import axios from 'axios';
import moment from 'moment';
import {
  ExclamationCircleOutlined,
} from '@ant-design/icons';

import './Bonus.scss';

const { Option } = Select;
const { confirm } = Modal;

class Bonus extends React.PureComponent {
  state = {
    dataSource: [],
    columns: [{
      title: '时间',
      dataIndex: 'date',
      key: 'date',
      width: 120,
      render: (value) => {
        return value.substring(0, 10)
      }
    }, {
      title: '股票名称',
      dataIndex: 'stockName',
      key: 'stockName'
    }, {
      title: '分红',
      dataIndex: 'bonus',
      key: 'bonus',
      render: (val) => {
        return val + '元';
      }
    }, {
      title: '备注',
      dataIndex: 'remark',
      key: 'remark'
    }, {
      title: '操作',
      width: '130px',
      render: (val, recorder: any) => {
        return (
          <React.Fragment>
            <span className='table-warning' onClick={ () => { this.modifyBonus(recorder) } }>编辑</span>
            <span className='table-delete' onClick={ () => { this.deleteBonus(recorder._id) } }>删除</span>
          </React.Fragment>
        )
      }
    }],
    selectYear: (new Date()).getFullYear(),
    page: 1,
    pageSize: 100,
    total: 0,
    gridYear: [],
    bonusModal: 0,
    date: moment(`${ (new Date()).getFullYear() }-${ (new Date()).getMonth() + 1 }-${ (new Date()).getDate() }`, 'YYYY-MM-DD'),
    stock: [],
    gridCodeTypes: [],
    bonus: '',
    remark: '',
    modifyId: '',
    allBonus: '0.00',
  };

  changeInput = (name, val) => {
    if (name === 'stock' && val.length > 1) {
      // 只能选一个哦
      this.setState({
        [name]: [val[1]]
      });

      return;
    }
    this.setState({
      [name]: val
    });
  }

  searchBonus = () => {
    this.getBonus();
  }

  addNewBonus = () => {
    this.setState({
      bonusModal: 1,
      date: moment(`${ (new Date()).getFullYear() }-${ (new Date()).getMonth() + 1 }-${ (new Date()).getDate() }`, 'YYYY-MM-DD'),
      stock: [],
      bonus: '',
      remark: '',
    });
  }
  modifyBonus = (rec) => {
    this.setState({
      bonusModal: 2,
      date: moment(rec.date.substring(0, 10), 'YYYY-MM-DD'),
      stock: [rec.stockCode],
      bonus: rec.bonus,
      remark: rec.remark,
      modifyId: rec._id,
    });
  }

  deleteBonus = (_id) => {
    confirm({
      title: 'Are you sure delete this task?',
      icon: <ExclamationCircleOutlined />,
      content: '确定删除？',
      okText: '确定',
      okType: 'danger',
      cancelText: '取消',
      onOk: () => {
        axios.delete(`/bonus?_id=${ _id }`).then((res) => {
          message.success('删除成功');

          this.getBonus();
        }, () => {
          message.error('删除失败');
        });
      },
    });
  }

  handleOk = () => {
    if (!this.state.date || !this.state.stock.length || this.state.stock.length > 1 || !this.state.bonus) {
      message.warning('请输入内容');
      return;
    }
    this.handleCancel()

    const info: any = {
      date: new Date(this.state.date.format().substring(0, 10)),
      stockCode: this.state.stock[0],
      bonus: this.state.bonus,
      remark: this.state.remark,
    }
    let action

    if (this.state.bonusModal === 1) {
      action = axios.post
    } else {
      action = axios.put
      info._id = this.state.modifyId
    }

    action('/bonus', info).then((res) => {
      message.success('操作成功');
      this.getBonus();
      this.getGridCodeType();
    }, () => {
      message.error('操作失败');
    })
  }

  handleCancel = () => {
    this.setState({ bonusModal: 0 })
  }

  getBonus = () => {
    const year = this.state.selectYear

    axios.get(`/bonus?year=${ year }`).then((res: any) => {
      var all = 0;

      res.data.forEach(function(item) {
        all += +item.bonus;
      });
      this.setState({
        allBonus: all.toFixed(2),
        dataSource: res.data,
        page: res.page.page,
        pageSize: res.page.pageSize,
        total: res.page.total,
      });
    })
  }

  getGridCodeType = () => {
    axios.get(`/gridCodeType`).then((res) => {
      this.setState({
        gridCodeTypes: res.data || []
      });
    })
  }

  paginationChange = (page, pageSize) => {
    this.setState({
      page,
      pageSize,
    }, () => {
      this.getBonus();
    })
  }

  componentDidMount() {
    this.getBonus();
    this.getGridCodeType();
    // 初始化时间
    let thisYear = (new Date()).getFullYear();
    let years = [];

    new Array(thisYear - 2020).fill('1').forEach((item, ind) => {
      years.push(2020 + ind + 1);
    });

    this.setState({
      gridYear: years,
      selectYear: thisYear,
    });
  }

  render(): React.ReactNode {
    return (
      <div className='bonus'>
        <Row gutter={ 16 }>
          <Col span={ 4 }>
            <Select value={ this.state.selectYear } style={{ width: '100%' }} onChange={ (value) => { this.changeInput('selectYear', value) } }>
              <Option value="">全部</Option>
              {
                this.state.gridYear.map((item) => {
                  return (
                    <Option value={ item } key={ item }>{ item }年</Option>
                  )
                })
              }
            </Select>
          </Col>
          <Col span={ 2 } offset={ 16 }>
            <Button type='primary' onClick={ this.searchBonus }>查询</Button>
          </Col>
          <Col span={ 2 }>
            <Button onClick={ this.addNewBonus }>新增</Button>
          </Col>
        </Row>
        <br />
        <Descriptions
          bordered
        >
          <Descriptions.Item label="总分红">{ this.state.allBonus }元</Descriptions.Item>
        </Descriptions>
        <br />
        <Table
          sticky={ true }
          pagination={{ current: this.state.page, pageSize: this.state.pageSize, total: this.state.total, onChange: this.paginationChange }}
          bordered={ true }
          dataSource={ this.state.dataSource }
          columns={ this.state.columns }
          rowKey={ item => item._id }
        />
        {
          !!this.state.bonusModal && <Modal
            title={ this.state.bonusModal === 1 ? '新增' : '编辑'}
            visible={ !!this.state.bonusModal }
            onOk={ this.handleOk }
            onCancel={ this.handleCancel }
            width="840px"
          >
            <Row gutter={ 16 }>
              <Col span={ 3 } className='must'>时间</Col>
              <Col span={ 9 }>
                <DatePicker
                  style={{ width: '100%' }}
                  value={ this.state.date }
                  onChange={ (value) => { this.changeInput('date', value) }}
                />
              </Col>
              <Col span={ 3 } className='must'>股票</Col>
              <Col span={ 9 }>
                <Select mode="tags" value={ this.state.stock } style={{ width: '100%' }} onChange={ (value) => { this.changeInput('stock', value) } }>
                  {
                    this.state.gridCodeTypes.map((item) => {
                      return (
                        <Option value={ item.stockCode } key={ item.stockCode }>{ item.stockName }({ item.stockCode })</Option>
                      )
                    })
                  }
                </Select>
              </Col>
            </Row>
            <br />
            <Row gutter={ 16 }>
              <Col span={ 3 } className='must'>分红</Col>
              <Col span={ 9 }>
                <Input value={ this.state.bonus } onChange={ (e) => { this.changeInput('bonus', e.target.value) }} />
              </Col>
              <Col span={ 3 }>备注</Col>
              <Col span={ 9 }>
                <Input value={ this.state.remark } onChange={ (e) => { this.changeInput('remark', e.target.value) }} />
              </Col>
            </Row>
            <br />
          </Modal>
        }
      </div>
    )
  }
}

export default Bonus;
