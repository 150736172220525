import React, { useCallback, useEffect, useState } from 'react';
import { Space, Select, DatePicker, Col, Row } from 'antd';
import axios from 'axios';
import moment from 'moment';
import {
  PercentageOutlined,
  PayCircleOutlined,
} from '@ant-design/icons';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/line';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/graphic';
import 'echarts/lib/component/markLine';
import 'echarts/lib/component/markPoint';
import 'echarts/lib/component/legend';
import 'echarts/lib/chart/bar';
import 'echarts/lib/component/title';

import { dailyEarnChartConfig } from '../../chartConfig';
import './DailyIncome.scss';

const { RangePicker } = DatePicker;

let dailyChart = null;
let dailyEarnChart = null;

export const DailyIncome = ({ data }) => {
  const [dailyEarnType, setDailyEarnType] = useState<string>('num'); //  num || rate
  const [dailyEarnRangeType, setDailyEarnRangeType] = useState<string>('any'); // any || all

  const [dailyEarnStart, setDailyEarnStart] = useState<string>(moment().format('YYYY') + '-01-01');
  const [dailyEarnEnd, setDailyEarnEnd] = useState<string>(moment().format('YYYY-MM-DD'));

  const getParams = () => {
    if (dailyEarnRangeType !== 'all') {
      return `?startTime=${ dailyEarnStart }&endTime=${ dailyEarnEnd }`;
    }

    return '';
  }

  const getDaily = () => {
    axios.get(`/board/dailyAsset` + getParams()).then((res) => {
      let date = [];
      let data = [];
      const dailyEarnY = [];

      res.data.forEach((item) => {
        date.push(moment(item.date).format('YYYY-MM-DD'));
        data.push(item.total);
        dailyEarnY.push(dailyEarnType === 'num' ? item.cutOffEarn : `${ (item.cutOffRate * 100).toFixed(4) }`);
      });

      initDaily(data, date);
      setDailyEarnChart(dailyEarnY, date);
    });
  }

  const initDaily = (data, date) => {
    dailyChart = echarts.init(document.getElementById('daily'));

    dailyChart.setOption({
      tooltip: {
        trigger: 'axis',
        position: function (pt) {
          return [pt[0], '10%'];
        }
      },
      toolbox: {
        feature: {
          dataZoom: {
            yAxisIndex: 'none'
          },
          restore: {},
          saveAsImage: {}
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: date
      },
      yAxis: {
        type: 'value',
        boundaryGap: [0, '100%']
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 10
        },
        {
          start: 0,
          end: 10
        }
      ],
      series: [
        {
          name: '总净资产',
          type: 'line',
          symbol: 'none',
          sampling: 'lttb',
          itemStyle: {
            color: 'rgb(255, 70, 131)'
          },
          markPoint: {
            data: [
              {
                type: 'max',
                name: '最大值',
                label: {
                  color: '#000'
                },
                itemStyle: {
                  color: 'rgba(255, 158, 68, 0.5)'
                }
              },
              {
                type: 'min',
                name: '最小值',
                label: {
                  color: '#000',
                  // position: 'bottom'
                },
                itemStyle: {
                  color: 'rgba(255, 158, 68, 0.5)'
                }
              }
            ]
          },
          markLine: {
            data: [
              {
                type: 'average',
                name: '平均值',
                label: {
                  position: 'insideStartTop'
                }
              }
            ]
          },
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: 'rgb(255, 158, 68)'
              },
              {
                offset: 1,
                color: 'rgb(255, 70, 131)'
              }
            ])
          },
          data: data
        }
      ]
    });
  }

  const setDailyEarnChart = (data, date) => {
    if (!dailyEarnChart) {
      dailyEarnChart = echarts.init(document.getElementById('dailyEarn'));
    }

    dailyEarnChartConfig.xAxis.data = date;
    dailyEarnChartConfig.series[0].data = data;

    dailyEarnChart.setOption(dailyEarnChartConfig, true);
  }

  const changeDailyEarnType = () => {
    let type = 'num';

    if (dailyEarnType === 'num') {
      type = 'rate';
    }

    setDailyEarnType(type);
  }

  const dailyEarnRangeTypeChange = (value: string) => {
    setDailyEarnRangeType(value);
  }

  const dailyEarnRangeChange = (dates, dateStrings) => {
    setDailyEarnStart(dateStrings[0]);
    setDailyEarnEnd(dateStrings[1]);
  }

  useEffect(() => {
    if (dailyEarnStart && dailyEarnEnd || dailyEarnRangeType === 'any') {
      getDaily();
    }
  }, [dailyEarnStart, dailyEarnEnd, dailyEarnType, dailyEarnRangeType]);

  useEffect(() => {
    // 绘制收益曲线均线
    if (!dailyEarnChart) {
      dailyEarnChart = echarts.init(document.getElementById('dailyEarn'));
    }
    dailyEarnChartConfig.series[0].markLine.data[0].yAxis = data.newBonusAll + '';
    dailyEarnChart.setOption(dailyEarnChartConfig, true);
  }, [data.newBonusAll]);

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <h3>每日总净资产曲线</h3>
      <div id="daily" style={{ width: '100%', height: 400 }}></div>
      <h3>每日截止收益曲线
        <span className='type-change' style={{ marginLeft: '10px'}} onClick={ changeDailyEarnType }>
          {
            dailyEarnType === 'num' ? <PercentageOutlined /> : <PayCircleOutlined />
          }
        </span>
      </h3>
      <Space>
        <Select
          value={ dailyEarnRangeType }
          style={{ width: 120 }}
          onChange={ (value: string) => { dailyEarnRangeTypeChange(value) }}
          options={[
            {
              value: 'all',
              label: '全部',
            }, {
              value: 'any',
              label: '自定义',
            }, {
              value: 'last',
              label: '去年',
            },
          ]}
        />
        {
          dailyEarnRangeType === 'any' &&  <RangePicker
            ranges={{
              '最近七天': [moment().add(-8, 'd'), moment().add(-1, 'd')],
              '最近一个月': [moment().add(-31, 'd'), moment().add(-1, 'd')],
              '最近三个月': [moment().add(-1 - 30 * 3, 'd'), moment().add(-1, 'd')],
              '最近半年': [moment().add(-1 - 30 * 6, 'd'), moment().add(-1, 'd')],
              '最近一年': [moment().add(-1 - 365, 'd'), moment().add(-1, 'd')],
            }}
            allowClear={ false }
            value={ [moment(dailyEarnStart), moment(dailyEarnEnd)] }
            onChange={ dailyEarnRangeChange }
          />
        }
      </Space>
      <div id="dailyEarn" style={{ width: '100%', height: 400 }}></div>
    </Space>
  );
}