import React from 'react';
import { Table, Descriptions, Button, PageHeader, Row, Col, Modal, Input, message } from 'antd';
import axios from 'axios';

import './AccountDetail.scss';
import {
  ExclamationCircleOutlined,
} from '@ant-design/icons';

interface IProps {
  goBack: () => void
}
interface IState {}

const { confirm } = Modal;

class AccountDetail extends React.PureComponent<IProps, IState> {
  state = {
    dataSource: [],
    columns: [{
      title: '股票名称',
      dataIndex: 'stockName',
      key: 'stockName'
    }, {
      title: '股票代码',
      dataIndex: 'stockCode',
      key: 'stockCode'
    }, {
      title: '操作',
      width: '120px',
      render: (val, recorder) => {
        return (
          <span className='table-delete' onClick={ () => { this.deleteStock(recorder.stockCode) } }>删除</span>
        )
      }
    }],
    addModal: false,
    code: '',
    name: ''
  }

  addNew = () => {
    this.setState({
      addModal: true
    })
  }

  clearInput = () => {
    this.setState({
      code: '',
      name: ''
    })
  }

  deleteStock = (stockCode) => {
    confirm({
      title: 'Are you sure delete this task?',
      icon: <ExclamationCircleOutlined />,
      content: '确定删除？',
      okText: '确定',
      okType: 'danger',
      cancelText: '取消',
      onOk() {
        const belong = sessionStorage.getItem('accountId')

        axios.delete(`/holds?belong=${ belong }&stockCode=${ stockCode }`).then((res) => {
          if (!res.data.code) {
            message.success('删除成功');
            this.getTableInfo();
          } else {
            message.error('删除失败');
          }
        });
      },
    });
  }

  handleOk = () => {
    this.handleCancel()
    const belong = sessionStorage.getItem('accountId')

    axios.post('/holds', {
      belong,
      stockName: this.state.name,
      stockCode: this.state.code,
    }).then((res) => {
      this.clearInput();

      if (!res.data.code) {
        message.success('增加成功');
        this.getTableInfo();
      } else {
        message.error('增加失败');
      }
    })
  }

  handleCancel = () => {
    this.clearInput();
    this.setState({
      addModal: false
    })
  }

  changeInput = (name, val) => {
    this.setState({
      [name]: val
    })
  }

  componentDidMount() {
    this.getTableInfo();
  }

  getTableInfo = () => {
    const belong = sessionStorage.getItem('accountId')

    axios.get(`/holds?belong=${belong}`).then(((res) => {
      this.setState({
        dataSource: res.data.data
      })
    }))
  }

  render() {
    return (
      <div className="site-page-header-ghost-wrapper">
        <PageHeader
          ghost={false}
          onBack={ this.props.goBack }
          title="长城证券"
          subTitle="*********776"
          extra={[
            <Button key="1" type="primary" disabled>
              修改
            </Button>,
          ]}
        >
          <Descriptions column={3}>
            <Descriptions.Item label="券商">长城证券</Descriptions.Item>
            <Descriptions.Item label="证券账号">*********776</Descriptions.Item>
            <Descriptions.Item label="两融">已开通</Descriptions.Item>
            <Descriptions.Item label="免5">是</Descriptions.Item>
            <Descriptions.Item label="手续费">
              股票：0.00012<br />
              基金：0.00005
            </Descriptions.Item>
          </Descriptions>
          <br />
        </PageHeader>
        <Row>
          <Col span={ 2 }>持仓</Col>
          <Col span={ 1 } offset={ 20 }>
            <Button type="primary" onClick={ this.addNew }>新增</Button>
          </Col>
        </Row>
        <br />
        <Table
          bordered={ true }
          pagination={ false }
          dataSource={ this.state.dataSource }
          columns={ this.state.columns }
          rowKey={ item => item.stockCode }
        />
        {
          this.state.addModal && <Modal
            title="新增"
            visible={ this.state.addModal }
            onOk={ this.handleOk }
            onCancel={ this.handleCancel }
            width="600px"
          >
            <Row>
              <Col span={ 6 } className='must'>股票名称</Col>
              <Col span={ 18 }>
                <Input value={ this.state.name } onChange={ (e) => { this.changeInput('name', e.target.value) }} />
              </Col>
            </Row>
            <br />
            <Row>
              <Col span={ 6 } className='must'>股票代码</Col>
              <Col span={ 18 }>
                <Input value={ this.state.code } onChange={ (e) => { this.changeInput('code', e.target.value) }} />
              </Col>
            </Row>
          </Modal>
        }
      </div>
    )
  }
}

export default AccountDetail;
