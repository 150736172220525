import React from 'react';
import { Table, Select, Row, Col, Button, Modal, Input, DatePicker, message, InputNumber, Descriptions, Space } from 'antd';
import axios from 'axios';
import moment from 'moment';
import {
  ExclamationCircleOutlined,
} from '@ant-design/icons';

import { getDayForMonth, round2 } from '../../utils/tools';

import './Grid.scss'

const { Option } = Select;
const { confirm } = Modal;

class Grid extends React.PureComponent {
  state = {
    dataSource: [],
    columns: [{
      title: '时间',
      dataIndex: 'date',
      key: 'date',
      width: 120,
      render: (value) => {
        return value.substring(0, 10)
      }
    }, {
      title: '股票名称',
      dataIndex: 'stockName',
      key: 'stockName'
    }, {
      title: '买入价格',
      dataIndex: 'buy',
      key: 'buy'
    }, {
      title: '卖出价格',
      dataIndex: 'sell',
      key: 'sell'
    }, {
      title: '股数',
      dataIndex: 'num',
      key: 'num'
    }, {
      title: '佣金',
      dataIndex: 'commission',
      key: 'commission'
    }, {
      title: '印花税',
      dataIndex: 'stampTax',
      key: 'stampTax'
    }, {
      title: '过户费',
      dataIndex: 'transferFee',
      key: 'transferFee'
    }, {
      title: '交易费用',
      dataIndex: 'cost',
      key: 'cost'
    }, {
      title: '收益',
      dataIndex: 'profit',
      key: 'profit'
    }, {
      title: '操作',
      width: '130px',
      render: (val, recorder) => {
        return (
          <React.Fragment>
            <span className='table-warning' onClick={ () => { this.modifyGrid(recorder) } }>编辑</span>
            <span className='table-delete' onClick={ () => { this.deleteGrid(recorder._id) } }>删除</span>
          </React.Fragment>
        )
      }
    }],
    gridYear: [],
    selectAccount: '',
    selectYear: (new Date()).getFullYear(),
    selectMonth: (new Date()).getMonth() + 1,
    selectDay: '',
    selectCode: '',
    gridModal: 0, // 0 表示关闭，1 表示增加状态 2 表示编辑状态
    gridCodeTypes: [],

    page: 1,
    pageSize: 50,
    total: 0,
    tradingDay: 0,
    averageProfit: 0,

    account: '',
    stock: [],
    buy: '',
    sell: '',
    num: 100,
    date: moment(`${ (new Date()).getFullYear() }-${ (new Date()).getMonth() + 1 }-${ (new Date()).getDate() }`, 'YYYY-MM-DD'),
    allEarnings: 0,
    cost: 0,
    bugAll: 0,
    sellAll: 0,
    modifyId: ''
  }

  deleteGrid = (id) => {
    confirm({
      title: 'Are you sure delete this task?',
      icon: <ExclamationCircleOutlined />,
      content: '确定删除？',
      okText: '确定',
      okType: 'danger',
      cancelText: '取消',
      onOk: () => {
        axios.delete(`/grid?_id=${ id }`).then((res) => {
          message.success('删除成功');

          this.getGrid();
        }, () => {
          message.error('删除失败');
        });
      },
    });
  }

  modifyGrid = (rec) => {
    this.setState({
      modifyId: rec._id,
      buy: rec.buy,
      sell: rec.sell,
      num: rec.num,
      date: moment(rec.date.substring(0, 10), 'YYYY-MM-DD'),
      stock: [rec.stockCode],
      gridModal: 2
    })
  }

  changeInput = (name, val) => {
    if (name === 'stock' && val.length > 1) {
      // 只能选一个哦
      this.setState({
        [name]: [val[1]]
      });

      return;
    }

    if (['selectYear', 'selectMonth', 'selectDay', 'selectCode'].includes(name)) {
      this.setState({
        page: 1,
      })
    }

    this.setState({
      [name]: val
    });
  }

  addNewGrid = () => {
    this.setState({
      gridModal: 1,
      // date: moment(`${ (new Date()).getFullYear() }-${ (new Date()).getMonth() + 1 }-${ (new Date()).getDate() }`, 'YYYY-MM-DD'),
      stock: [],
      buy: '',
      sell: '',
      num: 100,
    })
  }

  handleOk = () => {
    this.handleCancel()

    const info: any = {
      date: new Date(this.state.date.format().substring(0, 10)),
      stockCode: this.state.stock[0],
      buy: this.state.buy,
      sell: this.state.sell,
      num: this.state.num - 0,
    }
    let action

    if (this.state.gridModal === 1) {
      action = axios.post
    } else {
      action = axios.put
      info._id = this.state.modifyId
    }

    action('/grid', info).then((res) => {
      message.success('操作成功');
      this.getGrid();
      this.getGridCodeType();

      // 恢复默认
      this.setState({
        buy: '',
        sell: '',
        num: 100
      })
    }, () => {
      message.error('操作失败');
    })
  }

  getGridCodeType = () => {
    axios.get(`/gridCodeType`).then((res) => {
      this.setState({
        gridCodeTypes: res.data || []
      });
    })
  }

  handleCancel = () => {
    this.setState({ gridModal: 0 })
  }

  paginationChange = (page, pageSize) => {
    this.setState({
      page,
      pageSize,
    }, () => {
      this.getGrid();
    })
  }

  getGrid = () => {
    const year = this.state.selectYear
    const month = this.state.selectMonth
    const day = this.state.selectDay

    return axios.get(`/grid?code=${ this.state.selectCode }&year=${ year }&month=${ year && month ? month : '' }&day=${ year && month && day ? day : '' }&page=${ this.state.page }&pageSize=${ this.state.pageSize }`).then((res: any) => {
      this.setState({
        dataSource: res.data,
        allEarnings: round2(res.allEarnings),
        cost: round2(res.cost),
        bugAll: res.bugAll,
        sellAll: res.sellAll,
        tradingDay: res.tradingDay,

        page: res.page.page,
        pageSize: res.page.pageSize,
        total: res.page.total,
        averageProfit: (res.allEarnings / (res.tradingDay || 1)).toFixed(2),
      });
    })
  }

  searchGrid = () => {
    this.getGrid().then(function() {
      message.success('查询成功');
    });
  }

  componentDidMount() {
    this.getGridCodeType();
    this.getGrid();

    // 初始化时间
    let thisYear = (new Date()).getFullYear();
    let years = [];

    new Array(thisYear - 2020).fill('1').forEach((item, ind) => {
      years.push(2020 + ind + 1);
    });

    this.setState({
      gridYear: years,
      selectYear: thisYear,
    });
  }

  render() {
    return (
      <div className='grid'>
        <Row gutter={ 16 }>
          <Col md={ 4 } xs={ 12 }>
            <Select value={ this.state.selectYear } style={{ width: '100%' }} onChange={ (value) => { this.changeInput('selectYear', value) } }>
              <Option value="">全部</Option>
              {
                this.state.gridYear.map((item) => {
                  return (
                    <Option value={ item } key={ item }>{ item }年</Option>
                  )
                })
              }
            </Select>
          </Col>
          <Col md={ 4 } xs={ 12 }>
            {
              Number(this.state.selectYear) !== 0 && <Select value={ this.state.selectMonth } style={{ width: '100%' }} onChange={ (value) => { this.changeInput('selectMonth', value) } }>
                <Option value="">全部</Option>
                {
                  Array.from(new Array(12)).map((item, ind) => {
                    return (
                      <Option value={ ind + 1 } key={ ind }>{ ind + 1 }月</Option>
                    )
                  })
                }
              </Select>
            }
          </Col>
          {
            Number(this.state.selectYear) !== 0 && Number(this.state.selectMonth) !== 0 && <Col md={ 4 } xs={ 12 }>
              <Select value={ this.state.selectDay } style={{ width: '100%' }} onChange={ (value) => { this.changeInput('selectDay', value) } }>
                <Option value="">全部</Option>
                {
                  Array.from(new Array(getDayForMonth(this.state.selectYear, this.state.selectMonth))).map((item, ind) => {
                    return (
                      <Option value={ ind + 1 } key={ ind }>{ ind + 1 }日</Option>
                    )
                  })
                }
              </Select>
            </Col>
          }
          <Col md={ 4 } xs={ 12 }>
            {
              <Select value={ this.state.selectCode } style={{ width: '100%' }} onChange={ (value) => { this.changeInput('selectCode', value) } }>
                <Option value="">全部</Option>
                {
                  this.state.gridCodeTypes.map((item) => {
                    return (
                      <Option value={ item.stockCode } key={ item.stockCode }>{ item.stockName }({ item.stockCode })</Option>
                    )
                  })
                }
              </Select>
            }
          </Col>
          <Col md={ 3 } xs={ 6 }>
            <Space>
              <Button type='primary' onClick={ this.searchGrid }>查询</Button>
              <Button onClick={ this.addNewGrid }>新增</Button>
            </Space>
          </Col>
        </Row>
        <br />
        <Descriptions
          bordered
        >
          <Descriptions.Item label="总收益">{ this.state.allEarnings }</Descriptions.Item>
          <Descriptions.Item label="总交易费用">{ this.state.cost }</Descriptions.Item>
          <Descriptions.Item label="总交易天数">{ this.state.tradingDay }</Descriptions.Item>
          <Descriptions.Item label="日均收益">{ this.state.averageProfit }</Descriptions.Item>
          <Descriptions.Item label="交易费用占比">{ (this.state.cost / (this.state.allEarnings || 1) * 100).toFixed(4) }%</Descriptions.Item>
          <Descriptions.Item label="总交易次数">{ this.state.total }</Descriptions.Item>
          <Descriptions.Item label="总买入流水">{ this.state.bugAll }</Descriptions.Item>
          <Descriptions.Item label="总卖出流水">{ this.state.sellAll }</Descriptions.Item>
        </Descriptions>
        <br />
        <Table
          sticky={ true }
          pagination={{ current: this.state.page, pageSize: this.state.pageSize, total: this.state.total, onChange: this.paginationChange }}
          bordered={ true }
          dataSource={ this.state.dataSource }
          columns={ this.state.columns }
          rowKey={ item => item._id }
        />
        {
          !!this.state.gridModal && <Modal
            title={ this.state.gridModal === 1 ? '新增' : '编辑'}
            visible={ !!this.state.gridModal }
            onOk={ this.handleOk }
            onCancel={ this.handleCancel }
            width="840px"
          >
            <Row gutter={ 16 }>
              <Col md={ 3 } xs={ 6 } className='must'>时间</Col>
              <Col md={ 9 } xs={ 18 }>
                <DatePicker
                  style={{ width: '100%' }}
                  value={ this.state.date }
                  onChange={ (value) => { this.changeInput('date', value) }}
                />
              </Col>
              <Col md={ 3 } xs={ 6 } className='must'>股票</Col>
              <Col md={ 9 } xs={ 18 }>
                <Select mode="tags" value={ this.state.stock } style={{ width: '100%' }} onChange={ (value) => { this.changeInput('stock', value) } }>
                  {
                    this.state.gridCodeTypes.map((item) => {
                      return (
                        <Option value={ item.stockCode } key={ item.stockCode }>{ item.stockName }({ item.stockCode })</Option>
                      )
                    })
                  }
                </Select>
              </Col>
            </Row>
            <br />
            <Row gutter={ 16 }>
              <Col md={ 3 } xs={ 6 } className='must'>买入价格</Col>
              <Col md={ 9 } xs={ 18 }>
                <Input value={ this.state.buy } onChange={ (e) => { this.changeInput('buy', e.target.value) }} />
              </Col>
              <Col md={ 3 } xs={ 6 } className='must'>卖出价格</Col>
              <Col md={ 9 } xs={ 18 }>
                <Input value={ this.state.sell } onChange={ (e) => { this.changeInput('sell', e.target.value) }} />
              </Col>
            </Row>
            <br />
            <Row gutter={ 16 }>
              <Col md={ 3 } xs={ 6 } className='must'>股数</Col>
              <Col md={ 9 } xs={ 18 }>
                <InputNumber min={ 100 } step={ 100 } value={ this.state.num } onChange={ (value) => { this.changeInput('num', value) }} style={{ width: '100%' }} />
              </Col>
            </Row>
            <br />
          </Modal>
        }
      </div>
    )
  }
}

export default Grid;
