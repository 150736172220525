import { observable, action } from "mobx";
import Services from '@/services'

class User {

  @observable username = '';
  @observable name = '1111';
  @observable accountNum = 0;
  @observable stockAccounts = [];

  @action getAccount () {
    Services.getAccount().then((res) => {
      this.stockAccounts = [...res.data];
    });
  }
}

export default User;
