import React from 'react';
import { Table, Select, Row, Col, Button, Modal, Input } from 'antd';
import axios from 'axios';
import moment from 'moment';

import './Comment.scss';

class Comment extends React.PureComponent {

  render() {
    return <div className=''>
      comment
    </div>
  }
}

export default Comment;
