import React from 'react';
import { Typography, Descriptions, Table } from 'antd';
import axios from 'axios';
import moment from 'moment';

const { Paragraph, Text } = Typography;

class Aged extends React.PureComponent {
  state = {
    columns: [],
    dataSource: [],
    page: 1,
    pageSize: 100,
    total: 0,
    agedInfo: {
      allNum: '0',
      tradeNum: '0',
      bonusNum: '0',
      agedEarnNum: '0',
      used: '0',
      left: '0',
      usedPercent: '0%',
    },
  };

  paginationChange = (page, pageSize) => {
    this.setState({
      page,
      pageSize,
    }, () => {
      this.getAged();
    })
  }

  getAged = () => {
    axios.get(`/aged`).then((res: any) => {
      if (res.code === 200) {
        this.setState({
          agedInfo: {
            ...res.data,
            usedPercent: res.data.used / res.data.allNum * 100 + '%'
          }
        });
      }
    })
  }

  getAgedHold = () => {
    axios.get(`/agedHold?page=${ this.state.page }&pageSize=${ this.state.pageSize }`).then((res) => {
      console.log(res);
      // this.setState({
      //   gridCodeTypes: res.data || []
      // });
    })
  }

  componentDidMount() {
    this.getAged();
    this.getAgedHold();
  }

  render () {
    return (
      <div>
        <Paragraph>
          养老计划资金来源于网格收益与做T收益。
        </Paragraph>
        <Descriptions
          bordered
        >
          <Descriptions.Item label="累计网格收益">{ this.state.agedInfo.tradeNum }</Descriptions.Item>
          <Descriptions.Item label="累计分红收益">{ this.state.agedInfo.bonusNum }</Descriptions.Item>
          <Descriptions.Item label="累计计划收益">{ this.state.agedInfo.agedEarnNum }</Descriptions.Item>
          <Descriptions.Item label="总可用资金">{ this.state.agedInfo.allNum }</Descriptions.Item>
          <Descriptions.Item label="已经使用">{ this.state.agedInfo.used }</Descriptions.Item>
          <Descriptions.Item label="剩余">{ this.state.agedInfo.left }</Descriptions.Item>
          <Descriptions.Item label="已经使用比例">{ this.state.agedInfo.usedPercent }</Descriptions.Item>
        </Descriptions>
        <br />
        <Text>
          养老持有情况：
        </Text>
        <br />
        <Table
          sticky={ true }
          pagination={{ current: this.state.page, pageSize: this.state.pageSize, total: this.state.total, onChange: this.paginationChange }}
          bordered={ true }
          dataSource={ this.state.dataSource }
          columns={ this.state.columns }
          rowKey={ item => item._id }
        />
      </div>
    )
  }
}

export default Aged;
