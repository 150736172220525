import React from 'react';
import { Table, Select, Row, Col, Button, Modal, Checkbox, } from 'antd';
import axios from 'axios';
import moment from 'moment';

import './Visit.scss';

class Visit extends React.PureComponent {
  state = {
    columns: [{
      title: '时间',
      dataIndex: 'date',
      key: 'date',
      width: 180,
      render: (value) => {
        return moment(value).format('YYYY-MM-DD HH:mm:ss');
      }
    }, {
      title: '访问地址',
      dataIndex: 'visitPath',
      key: 'visitPath',
      render: (value) => {
        return <a href={ value } target='_blank'>{ decodeURIComponent(value) }</a>;
      }
    }, {
      title: '指纹',
      dataIndex: 'fingerprint',
      key: 'fingerprint',
      width: 200,
    }, {
      title: '机器人',
      dataIndex: 'isbot',
      key: 'isbot',
      render: (value) => {
        if (value !== undefined) {
          return value ? '是' : '否';
        }
        return '-';
      }
    }/* , {
      title: 'referer值',
      dataIndex: 'referer',
      key: 'referer',
      ellipsis: true,
      width: 300,
      render: (value) => {
        const val = decodeURIComponent(value);
        return <span title={ value }>{ value }</span>
      }
    } */],
    dataSource: [],

    page: 1,
    pageSize: 20,
    total: 0,
    filterBot: true,
  }

  paginationChange = (page, pageSize) => {
    this.setState({
      page,
      pageSize,
    }, () => {
      this.getVisit();
    })
  }

  search = () => {
    this.setState({
      page: 1,
    }, () => {
      this.getVisit();
    })
  }

  changeFilterBot = () => {
    this.setState({
      filterBot: !this.state.filterBot,
    }, () => {
      this.getVisit();
    })
  }

  getVisit = () => {
    axios.get(`/blog/visit?page=${ this.state.page }&pageSize=${ this.state.pageSize }&isbot=${ !this.state.filterBot }`).then((res: any) => {
      this.setState({
        dataSource: res.data,

        page: res.page.page,
        pageSize: res.page.pageSize,
        total: res.page.total,
      })
    })
  }

  componentDidMount() {
    this.getVisit();
  }

  render() {
    return <div className=''>
      <Row gutter={ 16 }>
        <Col span={ 4 }>
          <Checkbox checked={ this.state.filterBot } onChange={ this.changeFilterBot }>过滤机器人</Checkbox>
        </Col>
      </Row>
      <br />
      <Table
        bordered={ true }
        dataSource={ this.state.dataSource }
        pagination={{ current: this.state.page, pageSize: this.state.pageSize, total: this.state.total, onChange: this.paginationChange }}
        columns={ this.state.columns }
        rowKey={ item => item._id }
      />
    </div>
  }
}

export default Visit;
